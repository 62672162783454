import {
  Button,
  CircularProgress,
  createTheme,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../../../api/axios";
import { integrationsState, newIossNumberAnimationState } from "../../../atoms/membership";
import SpringIn from "../addressInfo/SpringIn";
import { proformaModalState } from "../../../atoms/createShipment";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
        },
      },
    },
  },
});

const CreateIossNumber = () => {
  const [newIossNumberAnimation, setNewIossNumberAnimation] = useRecoilState(
    newIossNumberAnimationState
  );
  const setProformaModal = useSetRecoilState(proformaModalState);
  const [integrations, setIntegrations] = useRecoilState(integrationsState);

  const [marketplace, setMarketplace] = useState("");
  const [iossNumber, setIossNumber] = useState("");
  const [marketplaceIsNotSelected, setMarketplaceIsNotSelected] = useState(false);
  const [iossNumberIsNotSelected, setIossNumberIsNotSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const iossNumberRef = useRef();
  const marketplaceRef = useRef();

  const iossNumberRegisterHandler = (e) => {
    e?.preventDefault();

    if (marketplace === "etsy_gb") {
      if (iossNumber.length !== 9) {
        setIossNumberIsNotSelected(true);
        return;
      }
    } else if (marketplace === "etsy_nw") {
      if (iossNumber.length!== 11) {
        setIossNumberIsNotSelected(true);
        return;
      }
    } else {
      if (iossNumber.length !== 12) {
        setIossNumberIsNotSelected(true);
        return;
      }
    }

    iossNumber.length !== 0 ? setIossNumberIsNotSelected(false) : setIossNumberIsNotSelected(true);

    marketplace.length !== 0
      ? setMarketplaceIsNotSelected(false)
      : setMarketplaceIsNotSelected(true);

    if (iossNumber.length !== 0 && marketplace.length !== 0) {
      setLoading(true);
      axios
        .post("member-ioss-number/add", {
          market_place: marketplace,
          ioss_number: iossNumber,
          specific_country: marketplace === "NO" ? "NO" : marketplace === "CH" ? "CH" : "",
        })
        .then((response) => {
          toast.success("IOSS/VAT Numarası Başarıyla Eklendi", { autoClose: 500 });
          // Response ile data döndüğü zaman aşağıdaki set işlemi yorum satırından çıkacak.
          setIntegrations([response.data.data, ...integrations]);
        })
        .catch((error) => Errors(error))
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  const handleClose = () => {
    setMarketplace("");
    setIossNumber("");
    setMarketplaceIsNotSelected(false);
    setIossNumberIsNotSelected(false);
    setNewIossNumberAnimation(false);
    setProformaModal(false);
  };

  useEffect(() => {
    if (iossNumber.length !== 0) {
      setIossNumberIsNotSelected(false);
    }

    if (marketplace.length !== 0) {
      setMarketplaceIsNotSelected(false);
    }
  }, [iossNumber, marketplace]);

  return (
    <>
      {newIossNumberAnimation && (
        <ThemeProvider theme={theme}>
          <SpringIn>
            <form
              className="create-ioss-number"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--gap2x)",
                marginTop: "var(--gap2x)",
              }}
              onSubmit={iossNumberRegisterHandler}
            >
              <div
                style={{
                  display: "grid",
                  gap: "var(--gap2x)",
                  gridTemplateColumns: "repeat(auto-fit, minmax(150px , 1fr))",
                }}
              >
                <FormControl fullWidth required size="small" error={marketplaceIsNotSelected}>
                  <InputLabel id="select-marketplace">Platform</InputLabel>
                  <Select
                    value={marketplace}
                    label="Platform"
                    ref={marketplaceRef}
                    labelId="select-marketplace"
                    autoFocus
                    onChange={(e) => setMarketplace(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Temizle</em>
                    </MenuItem>
                    <MenuItem value={"etsy"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/etsy.svg"
                          alt="Etsy Marka Görseli"
                        />
                      </div>{" "}
                      Etsy (EU)
                    </MenuItem>
                    <MenuItem value={"etsy_gb"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/etsy_gb.svg"
                          alt="Etsy Marka Görseli"
                        />
                      </div>{" "}
                      Etsy (UK)
                    </MenuItem>
                    <MenuItem value={"etsy_nw"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/etsy_nw.svg"
                          alt="Etsy Marka Görseli"
                        />
                      </div>{" "}
                      Etsy (Norveç)
                    </MenuItem>
                    <MenuItem value={"etsy_sw"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/etsy_sw.svg"
                          alt="Etsy Marka Görseli"
                        />
                      </div>{" "}
                      Etsy (İsviçre)
                    </MenuItem>
                    <MenuItem value={"amazon"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/amazon.svg"
                          alt="Amazon Marka Görseli"
                        />
                      </div>{" "}
                      Amazon
                    </MenuItem>
                    <MenuItem value={"aliexpress"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/aliexpress.svg"
                          alt="AliExpress Marka Görseli"
                        />
                      </div>{" "}
                      AliExpress
                    </MenuItem>
                    <MenuItem value={"wish"}>
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <img
                          style={{
                            height: "20px",
                          }}
                          src="/images/integrations/wish.svg"
                          alt="Wish Marka Görseli"
                        />
                      </div>{" "}
                      Wish
                    </MenuItem>
                  </Select>
                  {marketplaceIsNotSelected ? (
                    <FormHelperText>Marketplace seçmeniz gerekmektedir.</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
                <TextField
                  fullWidth
                  required
                  size="small"
                  autoComplete="off"
                  label="IOSS/VAT Numarası"
                  ref={iossNumberRef}
                  error={iossNumberIsNotSelected}
                  helperText={
                    iossNumberIsNotSelected
                      ? marketplace === "etsy_gb"
                        ? "IOSS/VAT numarası 9 hanelidir" :
                        marketplace === "etsy_nw"
                          ? "IOSS/VAT numarası 11 hanelidir"
                          : "IOSS/VAT numarası 12 hanelidir."
                      : ""
                  }
                  value={iossNumber}
                  inputProps={{ maxLength: marketplace === "etsy_gb" ? 9 : marketplace === "etsy_nw" ? 11 : 12 }}
                  onChange={(e) => setIossNumber(e.target.value.replace(/\s+/g, "").toUpperCase())}
                />
              </div>
              <div
                style={{
                  gap: "var(--gap)",
                }}
                className="new-address-register"
              >
                {loading ? (
                  <div
                    style={{
                      width: "108px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        width: "33px !important",
                        height: "33px !important",
                      }}
                    />
                  </div>
                ) : (
                  <Button sx={{ height: "35px" }} type="submit" className="iyzi-button-blue">
                    Kaydet
                  </Button>
                )}
                <Button
                  type="button"
                  sx={{ height: "35px" }}
                  className="iyzi-button-blue"
                  onClick={handleClose}
                >
                  İptal
                </Button>
              </div>
            </form>
          </SpringIn>
        </ThemeProvider>
      )}
    </>
  );
};

export default CreateIossNumber;
