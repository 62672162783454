import { atom } from "recoil";

export const portalState = atom({
  key: "portal",
  default: "",
});

export const contractCodeState = atom({
  key: "contractCode",
  default: "",
});

export const dimensionState = atom({
  key: "dimension",
  default: "",
});

export const priceIDValueState = atom({
  key: "priceIDValue",
  default: 0,
});

export const portalDataState = atom({
  key: "portalData",
  default: [],
});

export const insuranceFastState = atom({
  key: "insuranceFast",
  default: 0,
});

export const ddpFastState = atom({
  key: "ddpFast",
  default: 0,
});

export const lucidFastState = atom({
  key: "lucidFast",
  default: 0,
});

export const fastLimitState = atom({
  key: "fastLimit",
  default: 20,
});

export const fastListState = atom({
  key: "fastList",
  default: [],
});

export const uploadFileModalState = atom({
  key: "uploadFileModal",
  default: false,
});

export const fastShipmentTotalPagesState = atom({
  key: "fastShipmentTotalPages",
  default: 0,
});

export const fastShipmentCurrentPageState = atom({
  key: "fastShipmentCurrentPage",
  default: 1,
});

export const fastSenderAddressesState = atom({
  key: "fastSenderAddresses",
  default: [],
});

export const fastDimensionState = atom({
  key: "fastDimension",
  default: [],
});

export const selectedFilesIntState = atom({
  key: "selectedFilesInt",
  default: [],
});

export const openFastDimensionState = atom({
  key: "openFastDimension",
  default: false,
});

export const errorModalOpenState = atom({
  key: "errorModalOpen",
  default: false,
});

export const fileChangeState = atom({
  key: "fileChange",
  default: false,
});

export const openPackageListSaveState = atom({
  key: "openPackageListSave",
  default: false,
});

export const selectedFastShipmentProductState = atom({
  key: "selectedFastShipmentProduct",
  default: [],
});

export const fastCheckedState = atom({
  key: "fastChecked",
  default: [],
});

export const fastPricesState = atom({
  key: "fastPrices",
  default: [],
});

export const checkedFastInsurancesState = atom({
  key: "checkedFastInsurances",
  default: {},
});

export const checkedFastCustomsState = atom({
  key: "checkedFastCustoms",
  default: {},
});

export const checkedFastLucidsState = atom({
  key: "checkedFastLucids",
  default: {},
});

export const selectedPriceObjectState = atom({
  key: "selectedPriceObject",
  default: {},
});

// Search

export const shopIDState = atom({
  key: "shopID",
  default: "",
});
export const shopNameState = atom({
  key: "shopName",
  default: "",
});
export const countryState = atom({
  key: "country",
  default: "",
});
export const suppliersState = atom({
  key: "suppliers",
  default: "",
});
export const supplierState = atom({
  key: "supplier",
  default: [],
});
export const openFastSupplierState = atom({
  key: "openFastSupplier",
  default: false,
});
export const receiptIDState = atom({
  key: "receiptID",
  default: "",
});
export const buyerEmailState = atom({
  key: "buyerEmail",
  default: "",
});
export const receiverNameState = atom({
  key: "receiverName",
  default: "",
});
export const formattedAddressState = atom({
  key: "formattedAddress",
  default: "",
});
export const statusState = atom({
  key: "status",
  default: "",
});
export const parcelTypeState = atom({
  key: "parcelType",
  default: "",
});
export const isInvoiceFileState = atom({
  key: "isInvoiceFile",
  default: "",
});
export const parcelRefNoState = atom({
  key: "parcelRefNo",
  default: "",
});
export const receiptDateStartState = atom({
  key: "receiptDateStart",
  default: "",
});
export const receiptDateEndState = atom({
  key: "receiptDateEnd",
  default: "",
});
export const createDateStartState = atom({
  key: "createDateStart",
  default: "",
});
export const createDateEndState = atom({
  key: "createDateEnd",
  default: "",
});
export const receiptDateState = atom({
  key: "receiptDate",
  default: "",
});
export const createdDateState = atom({
  key: "createdDate",
  default: "",
});
export const dateTypeState = atom({
  key: "dateType",
  default: "",
});
