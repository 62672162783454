import "../../style/fastShipment/FastShipmentPrice.css";
import { Modal } from "@mui/material";
import { useCallback } from "react";
import FastShipmentPrices from "./fastShipmentPriceModal/FastShipmentPrices";

const FastShipmentPriceModal = ({
  status,
  setStatus,
  items,
  setSelectedPriceObject,
  setPriceID,
  setPriceIDValue,
  dataID,
  insuranceFast,
  setInsuranceFast,
  ddpFast,
  setDdpFast,
  lucidFast,
  setLucidFast,
  isAbove150EURCheck,
  setIsAbove150EURCheck,
  selectedFastShipmentProduct
}) => {
  // const sortedItems = [...item?.prices?.data]?.sort((a, b) => a?.selling_price - b?.selling_price);

  const handleClose = useCallback(() => {
    setStatus(false);
  }, [setStatus]);

  const member = JSON.parse(localStorage.getItem("user"));
  const memberId = member?.user?.user_member?.id;

  return (
    <Modal open={status} onClose={handleClose} className="get-card-modal">
      <div
        className="get-card-container"
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div className="get-card-wrapper">
          {items?.prices?.data?.map((item, key) => (
            <FastShipmentPrices
              key={key}
              item={item}
              items={items}
              setSelectedPriceObject={setSelectedPriceObject}
              handleClose={handleClose}
              setPriceID={setPriceID}
              setPriceIDValue={setPriceIDValue}
              dataID={dataID}
              insuranceFast={insuranceFast}
              setInsuranceFast={setInsuranceFast}
              ddpFast={ddpFast}
              setDdpFast={setDdpFast}
              lucidFast={lucidFast}
              setLucidFast={setLucidFast}
              isAbove150EURCheck={isAbove150EURCheck}
              setIsAbove150EURCheck={setIsAbove150EURCheck}
              selectedFastShipmentProduct={selectedFastShipmentProduct}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default FastShipmentPriceModal;
