import "../style/dashboard/Dashboard.css";
import DashboardShipmentCount from "../components/dashboard/DashboardShipmentCount";
import NotificationBar from "../components/sidebar/NotificationBar";
import { Box } from "@mui/system";
import { DynamicTitle } from "../utils/DynamicTitle";
import { useSetRecoilState } from "recoil";
import { emailState, nameState, surnameState } from "../atoms/membership";
import { useEffect, useState } from "react";
import WrapperStyle from "../utils/WrapperStyle";
import ProfileStatus from "../components/dashboard/ProfileStatus";
// import PageTransition from "../utils/PageTransition";
import { useCookies } from "react-cookie";
import axios from "../api/axios";
import ChangePasswordInfo from "../components/home/ChangePasswordInfo";

const Dashboard = () => {
  DynamicTitle("Pano | iyziShip");

  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const userInfo = JSON.parse(localStorage.getItem("user"));
  // const userStatus = userInfo?.user?.user_member?.status;

  // const setMemberStatusModal = useSetRecoilState(memberStatusModalState);
  const setName = useSetRecoilState(nameState);
  const setSurname = useSetRecoilState(surnameState);
  const setEmail = useSetRecoilState(emailState);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const isClosed = localStorage.getItem('ramadan-modal');

  useEffect(() => {
    const isClosed = localStorage.getItem('ramadan-modal');
    if (isClosed === '1') {
      setIsModalOpen(false);
    }
  }, []);

  useEffect(() => {
    setName(userInfo?.user.name);
    setSurname(userInfo?.user.surname);
    setEmail(userInfo?.user.email);
  }, [setName, setSurname, setEmail, userInfo]);

  // useEffect(() => {
  //   if (Number(userStatus) !== 2) {
  //     setMemberStatusModal(true);
  //   }
  // }, [setMemberStatusModal, userStatus]);

  return (
    <>
      <section className="dashboard">
        <NotificationBar />
        <ChangePasswordInfo open={isModalOpen} setOpen={setIsModalOpen}  />
        {/* <PageTransition> */}
        <ProfileStatus />
        <Box style={WrapperStyle()} className="dashboard-wrapper">
          <DashboardShipmentCount />
        </Box>
        {/* </PageTransition> */}
      </section>
    </>
  );
};

export default Dashboard;
