import {
  Box,
  Button,
  FormControl,
  Modal,
  ThemeProvider,
  createTheme,
  TextField,
} from "@mui/material";
import { useRecoilState } from "recoil";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ParcelReturnIcon from "@mui/icons-material/AssignmentReturn";
import {
  selectedParcelReturnState,
  abroadPriceInformationOpenState,
  returnShipmentPaymentOpenState,
  selectedPaymentReturnShipmentState,
  returnShipmentInformationOpenState,
  showReturnShipmentInformationState,
} from "../../../atoms/returnShipment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ReturnShipmentAbroadPriceInformation = () => {
  const [abroadPriceInformationOpen, setAbroadPriceInformationOpen] = useRecoilState(
    abroadPriceInformationOpenState
  );
  const [selectedParcelReturn] = useRecoilState(selectedParcelReturnState);
  const [, setReturnShipmentPaymentOpen] = useRecoilState(returnShipmentPaymentOpenState);
  const [, selectedPaymentReturnShipment] = useRecoilState(selectedPaymentReturnShipmentState);
  const [, setReturnShipmentInformationOpen] = useRecoilState(returnShipmentInformationOpenState);
  const [, setShowReturnShipmentInformation] = useRecoilState(showReturnShipmentInformationState);
  const handlePayment = async () => {
    handleClose();
    selectedPaymentReturnShipment(selectedParcelReturn);
    setShowReturnShipmentInformation(false);
    setReturnShipmentPaymentOpen(true);
    setReturnShipmentInformationOpen({ status: true, modalType: "paymentSuccessful" });
  };

  const handleClose = () => {
    setAbroadPriceInformationOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={abroadPriceInformationOpen} onClose={handleClose}>
        {selectedParcelReturn.length !== 0 ? (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "500px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                cursor: "pointer",
                color: "var(--priceText)",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
            <h6
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--priceText)",
                marginBottom: "10px",
              }}
            >
              <ParcelReturnIcon />
              Yurtdışından Geri Gelen Eşya Masrafı
            </h6>
            <Box sx={{ display: "grid", gap: "var(--gap)" }}>
              <FormControl className="parcel-return-demand" size="small" sx={{ minWidth: 180 }}>
                <TextField
                  fullWidth
                  value={`${selectedParcelReturn?.arrival_cost} ${selectedParcelReturn?.currency}`}
                  size="small"
                  label="Yurt Dışından Geri Dönüş Navlun Ücreti"
                  sx={{ marginBottom: "20px" }}
                  disabled={true}
                />
                <span
                  style={{
                    fontSize: "inherit",
                    fontWeight: 700,
                    color: "var(--btnBgBlue)",
                  }}
                ></span>
              </FormControl>
            </Box>
            <Box
              sx={{ display: "grid", gap: "var(--gap)", alignItems: "end", justifyItems: "end" }}
            >
              <div>
                <Button
                  sx={{
                    backgroundColor: "var(--info)",
                    color: "var(--lighterBg)",
                    textTransform: "none",
                    transition: "all 150ms linear",
                    borderRadius: "var(--buttonBorderRadius)",
                    padding: "7px 16px",
                    ":hover": {
                      backgroundColor: "var(--blueBrand)",
                      transition: "all 150ms linear",
                    },
                  }}
                  onClick={() => handlePayment()}
                >
                  <ChevronRightIcon sx={{ marginRight: "5px" }} />
                  Ödeme Yap
                </Button>
              </div>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              padding: "20px",
              outline: 0,
              display: "grid",
              gap: "var(--gap)",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              position: "relative",
            }}
            style={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
          </Box>
        )}
      </Modal>
    </ThemeProvider>
  );
};

export default ReturnShipmentAbroadPriceInformation;
