import React, { useState, useCallback, useEffect } from 'react';
import { Box, Checkbox, Button, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material';
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../../api/axios";
import {
    fastCheckedState,
    fastListState,
    fastShipmentCurrentPageState,
    fastShipmentTotalPagesState,
    portalState,
    shopIDState,
    shopNameState,
    countryState,
    suppliersState,
    receiptIDState,
    buyerEmailState,
    receiverNameState,
    formattedAddressState,
    statusState,
    parcelRefNoState,
    receiptDateStartState,
    receiptDateEndState,
    createDateStartState,
    createDateEndState,
    receiptDateState,
    createdDateState,
    dateTypeState,
    priceIDValueState,
    fastSenderAddressesState,
    selectedPriceObjectState,
    contractCodeState,
    dimensionState,
    insuranceFastState,
    ddpFastState,
    lucidFastState,
    parcelTypeState
} from "../../../atoms/fastShipment";
import {
    selectedFileState,
    iossFastState,
} from "../../../atoms/membership";
import FastShipmentListItem from '../FastShipmentListItem';
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const FastShipmentListHead = ({ allChecked, handleSelectAll }) => {
    const [loading, setLoading] = useState(false);
    const [orderDirection, setOrderDirection] = useState("");
    const [columnType, setColumnType] = useState("");
    const [activeSort, setActiveSort] = useState([
        { column: "platform", direction: null },
        { column: "shop_name", direction: null },
        { column: "receipt_id", direction: null },
        { column: "country", direction: null },
        { column: "contact_name", direction: null },
        { column: "status", direction: null },
        { column: "parcel_ref_no", direction: null },
        { column: "supplier_id", direction: null },
        { column: "receipt_date", direction: null },
    ]);

    const portal = useRecoilValue(portalState);
    const [fastList, setFastList] = useRecoilState(fastListState);
    const [fastChecked, setFastChecked] = useRecoilState(fastCheckedState);
    const [fastShipmentTotalPages, setFastShipmentTotalPages] = useRecoilState(fastShipmentTotalPagesState);
    const [fastShipmentCurrentPage, setFastShipmentCurrentPage] = useRecoilState(fastShipmentCurrentPageState);

    const [shopID, setShopID] = useRecoilState(shopIDState);
    const [shopName, setShopName] = useRecoilState(shopNameState);
    const [country, setCountry] = useRecoilState(countryState);
    const [supplier, setSupplier] = useRecoilState(suppliersState);
    const [receiptID, setReceiptID] = useRecoilState(receiptIDState);
    const [buyerEmail, setBuyerEmail] = useRecoilState(buyerEmailState);
    const [receiverName, setReceiverName] = useRecoilState(receiverNameState);
    const [formattedAddress, setFormattedAddress] = useRecoilState(formattedAddressState);
    const [status, setStatus] = useRecoilState(statusState);
    const [parcelRefNo, setParcelRefNo] = useRecoilState(parcelRefNoState);
    const [receiptDateStart, setReceiptDateStart] = useRecoilState(receiptDateStartState);
    const [receiptDateEnd, setReceiptDateEnd] = useRecoilState(receiptDateEndState);
    const [createDateStart, setCreateDateStart] = useRecoilState(createDateStartState);
    const [createDateEnd, setCreateDateEnd] = useRecoilState(createDateEndState);
    const [receiptDate, setReceiptDate] = useRecoilState(receiptDateState);
    const [createdDate, setCreatedDate] = useRecoilState(createdDateState);
    const [dateType, setDateType] = useRecoilState(dateTypeState);
    const [parcelType, setParcelType] = useRecoilState(parcelTypeState);

    const handlePageChange = useCallback(async (column, pageValue) => {
        setLoading(true);
        setFastShipmentCurrentPage(pageValue);
        setFastChecked([]);
        const updatedSort = activeSort.map((sort) => {
            if (sort.column === column) {
                return {
                    ...sort,
                    direction: sort.direction === "ASC" ? "DESC" : "ASC",
                };
            }
            return {
                ...sort,
                direction: null,
            };
        });

        let sortExists = updatedSort.find((sort) => sort.column === column);
        if (!sortExists) {
            sortExists = { column: column, direction: "ASC" };
            updatedSort.push(sortExists);
        }

        setActiveSort(updatedSort);

        const currentDirection = sortExists.direction;
        setOrderDirection(currentDirection);
        setLoading(true);

        try {
            const response = await axios.get("general/integration-parcel", {
                params: {
                    ...(portal ? { portal } : {}),
                    limit: 20,
                    page: pageValue,
                    order_direction: currentDirection,
                    order_by: column,
                    detailed: 1,
                    is_excel: window.location.href.endsWith("create-fast-all-shipment") ? 1 : 0,
                    ...(shopID ? { platform: shopID } : {}),
                    ...(shopName ? { shop_name: shopName } : {}),
                    ...(country ? { country: country } : {}),
                    ...(supplier ? { supplier_id: supplier } : {}),
                    ...(receiptID ? { receipt_id: receiptID } : {}),
                    ...(buyerEmail ? { buyer_email: buyerEmail } : {}),
                    ...(receiverName ? { contact_name: receiverName } : {}),
                    ...(formattedAddress ? { formatted_address: formattedAddress } : {}),
                    ...(status ? { status: status } : {}),
                    ...(parcelRefNo ? { parcel_ref_no: parcelRefNo } : {}),
                    ...(dateType ? { date_type: dateType } : {}),
                    ...(receiptDateStart ? { start_date: receiptDateStart } : {}),
                    ...(receiptDateEnd ? { end_date: receiptDateEnd } : {}),
                    ...(createDateStart ? { start_date: createDateStart } : {}),
                    ...(createDateEnd ? { end_date: createDateEnd } : {}),
                    ...(parcelType ? { parcel_type: parcelType } : {}),
                },
            });
            setColumnType(column);
            setFastList(response.data.data);
            setFastShipmentTotalPages(response.data.totalPages);
            setFastShipmentCurrentPage(response.data.currentPage);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [activeSort, buyerEmail, country, createDateEnd, createDateStart, dateType, fastShipmentCurrentPage, formattedAddress, orderDirection, parcelRefNo, portal, receiptDateEnd, receiptDateStart, receiptID, receiverName, setFastList, setFastShipmentCurrentPage, setFastShipmentTotalPages, shopID, shopName, status, supplier]);

    const handleToggle = (option) => {
        setFastChecked((prevChecked) =>
            prevChecked.includes(option)
                ? prevChecked.filter((item) => item !== option) // Seçimi kaldır
                : [...prevChecked, option] // Seçimi ekle
        );
    };

    return (
        <TableRow>
            <TableCell sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{ fontSize: "13px", display: "flex" }}>
                    <Checkbox
                        checked={allChecked}
                        onChange={handleSelectAll}
                    />
                    Tümünü Seç ({fastChecked.length})
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("platform")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex",
                }}>
                    Platform
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "platform")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("shop_name")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Mağaza Adı
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "shop_name")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("receipt_id")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Sipariş No
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "receipt_id")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("receipt_date")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Sipariş Tarihi
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "receipt_date")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("country")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Ülke
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "country")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("contact_name")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Alıcı Adı
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "contact_name")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("status")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Durum
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "status")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("parcel_ref_no")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    REF No
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "parcel_ref_no")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell onClick={() => handlePageChange("supplier_id")} sx={{ fontWeight: 500, cursor: "pointer" }}>
                <Box sx={{
                    fontSize: "13px",
                    display: "flex"
                }}>
                    Taşıyıcı
                    <KeyboardArrowDownOutlinedIcon
                        sx={{
                            transform:
                                activeSort.find((sort) => sort.column === "supplier_id")?.direction ===
                                    "ASC"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                            transition: "transform 0.3s",
                            ":hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Box>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

const FastShipmentList = ({ setAllChecked, allChecked, profileSenderAddress }) => {
    const [fastList] = useRecoilState(fastListState);
    const [fastChecked, setFastChecked] = useRecoilState(fastCheckedState);
    const [priceIDValue, setPriceIDValue] = useRecoilState(priceIDValueState);
    const [selectedFile, setSelectedFile] = useRecoilState(selectedFileState);
    const fastSenderAddresses = useRecoilValue(fastSenderAddressesState);
    const [selectedPriceObject, setSelectedPriceObject] = useRecoilState(selectedPriceObjectState);
    const [dimension, setDimension] = useRecoilState(dimensionState);
    const [insuranceFast, setInsuranceFast] = useRecoilState(insuranceFastState);
    const [ddpFast, setDdpFast] = useRecoilState(ddpFastState);
    const [lucidFast, setLucidFast] = useRecoilState(lucidFastState);
    const [iossFast, setIossFast] = useRecoilState(iossFastState);
    const [contractCode, setContractCode] = useRecoilState(contractCodeState);
    const [fastSenderAddress, setFastSenderAddress] = useState(
        fastSenderAddresses.find((e) => e.is_default === "1")
    );

    const gw = Number(dimension?.gross) * Number(dimension?.qty);
    const vw =
        ((Number(dimension?.width) * Number(dimension?.height) * Number(dimension?.length)) / 5000) *
        Number(dimension?.qty);

    const isAllChecked = fastChecked.length === fastList.filter((item) => !item?.get_parcel && !item.additionalText && !item.parcel_ref_no).length;

    // Tümünü seç veya kaldır
    const handleToggleAll = () => {
        if (isAllChecked) {
            // Tüm seçimleri kaldır
            setFastChecked([]);
            setAllChecked(false);
        } else {
            // Tümünü seç
            const allCheckedItems = fastList
                .filter((item) => !item?.get_parcel && !item.additionalText) // Sadece `get_parcel === null` olanları al
                .map((item) => {
                    // Commodities verilerini mapleme
                    const commoduties = item?.get_commoduties?.map((e) => ({
                        title: e.title,
                        sku: e.sku === "null" || e.sku == null ? "" : e.sku,
                        parcel_type: e.parcel_type,
                        hs_code: e.hs_code,
                        country: "TR",
                        qty: Number(e.quantity),
                        price: Number(e.price),
                        currency: e.currency,
                        total_price: Number(e.total_price),
                        ioss: e.ioss === "null" || e.ioss == null ? "" : e.ioss,
                        integration_parcel_id: e.integration_parcel_id,
                        id: e.id
                    }));

                    // Dimension verilerini mapleme
                    const dimensionList = item?.get_dimensions?.map((e) => ({
                        dimension_id: e.id,
                    }));

                    // Her bir item için yeni bir nesne döndürme
                    return {
                        price_details: {
                            contract_code: item.prices.contract_code,
                            price_id: !item?.price_id ? item?.prices?.data?.find(price => price.supplier_id === item.supplier_id).price_id : item?.price_id,
                        },
                        sender_address_id: fastSenderAddress?.id || null,
                        receiver_address: {
                            contact_name: item?.contact_name || "",
                            email: item?.buyer_email || "",
                            address1: item?.address1 || "",
                            address2: item?.address2 || "",
                            postal_code: item?.postal_code || "",
                            phone: item?.phone || "",
                            city: item?.city || "",
                            state: item?.state || "",
                            state_code: item?.state_code || "",
                            town: item?.town || "",
                            country: item?.country || "",
                            address_type: 5,
                        },
                        parcel_type: item?.get_commoduties?.[0]?.parcel_type || "",
                        files: selectedFile ? [selectedFile] : [],
                        doc_type: "N",
                        incoterm:
                            Number(selectedPriceObject?.ddp?.ddp) === 0 &&
                                (selectedPriceObject?.supplier_id === "337" || selectedPriceObject?.supplier_id === "338")
                                ? "DDP"
                                : Number(selectedPriceObject?.ddp?.ddp) === 1
                                    ? "DDP"
                                    : "DDU",
                        sender_name: fastSenderAddress?.contact_name || "",
                        receiver_name: item?.contact_name || "",
                        sender_country: "TR",
                        receiver_country: item?.country || "",
                        insurance: insuranceFast,
                        qty: item?.get_commoduties?.reduce(
                            (accumulator, currentValue) => accumulator + (currentValue.quantity || 0),
                            0
                        ),
                        ddp: ddpFast,
                        lucid: lucidFast,
                        commoduties, // Commodities listesini ekleme
                        dimensionList, // Dimension listesini ekleme
                        isPriceTake: item?.isPriceTake,
                        parcel_ref_no: item?.parcelRefNo,
                        prices: item?.prices,
                        ioss: item?.get_commoduties[0]?.ioss === "null" || item?.get_commoduties[0]?.ioss == null ? "" : item?.get_commoduties[0]?.ioss,
                        integration_parcel_id: item?.id,
                        source: item?.platform || "",
                        platform: item?.platform || "",
                        parcel_source: 1,
                        receipt_id: item?.receipt_id || "",
                    };
                });

            setFastChecked(allCheckedItems);
            setAllChecked(true);
        }
    };

    useEffect(() => {
        setAllChecked(isAllChecked);
    }, [fastChecked]);

    // const handleSelectAll = useCallback(
    //     (event) => {
    //         const checked = event.target.checked;
    //         setAllChecked(checked);

    //         if (checked) {
    //             const allCheckedItems = fastList
    //                 .filter((item) => item?.get_parcel === null && !item?.parcelRefNo)
    //                 .map((item) => {
    //                     const commoduties = item?.get_commoduties?.map((e) => ({
    //                         title: e.title,
    //                         sku: e.sku,
    //                         hs_code: e.hs_code,
    //                         country: 'TR',
    //                         qty: Number(e.quantity),
    //                         price: Number(e.price),
    //                         currency: e.currency,
    //                         total_price: Number(e.total_price),
    //                         ioss: e.ioss === "null" ? " " : e.ioss,
    //                     }));
    //                     const dimensions = item?.get_dimensions?.map((e) => ({
    //                         dimension_id: e.id,
    //                     }));

    //                     const gw = Number(dimension?.gross) * Number(dimension?.qty);
    //                     const vw = ((Number(dimension?.width) * Number(dimension?.height) * Number(dimension?.length)) / 5000) * Number(dimension?.qty);

    //                     return {
    //                         price_details: {
    //                             contract_code: item?.prices?.contract_code,
    //                             price_id: Number(item?.price_id),
    //                         },
    //                         sender_address_id: item?.addressID,
    //                         receiver_address: {
    //                             contact_name: item?.contact_name,
    //                             email: item?.buyer_email,
    //                             address1: item?.address1,
    //                             address2: item?.address2,
    //                             postal_code: item?.postal_code,
    //                             phone: item?.phone,
    //                             city: item?.city,
    //                             state: item?.state,
    //                             state_code: item?.state_code,
    //                             town: item?.town,
    //                             country: item?.country,
    //                             address_type: 5,
    //                         },
    //                         parcel_type: item?.get_commoduties[0]?.parcel_type,
    //                         files: selectedFile ? [selectedFile] : [],
    //                         doc_type: 'N',
    //                         incoterm:
    //                             Number(selectedPriceObject?.ddp?.ddp) === 0 && (selectedPriceObject.supplier_id === '337' || selectedPriceObject.supplier_id === '338')
    //                                 ? 'DDP'
    //                                 : Number(selectedPriceObject?.ddp?.ddp) === 1
    //                                     ? 'DDP'
    //                                     : 'DDU',
    //                         sender_name: item?.senderName,
    //                         receiver_name: item?.contact_name,
    //                         sender_country: 'TR',
    //                         receiver_country: item?.country,
    //                         /*gw,
    //                         vw,
    //                         cw: vw > gw ? vw : gw,*/
    //                         insurance: insuranceFast,
    //                         qty: Number(item?.get_commoduties.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)),
    //                         ddp: ddpFast,
    //                         lucid: lucidFast,
    //                         /*dimensions: [
    //                             {
    //                                 length: Number(dimension.length),
    //                                 width: Number(dimension.width),
    //                                 height: Number(dimension.height),
    //                                 gross: Number(dimension.gross),
    //                                 qty: Number(dimension.qty),
    //                             },
    //                         ],*/
    //                         commoduties,
    //                         dimensions,
    //                         ioss: item?.get_commoduties[0]?.ioss === "null" ? " " : item?.get_commoduties[0]?.ioss,
    //                         integration_parcel_id: item?.id,
    //                         source: item?.platform,
    //                         platform: item?.platform,
    //                         parcel_source: 1,
    //                         receipt_id: item?.receipt_id,
    //                     };
    //                 });
    //             return alert("Please");
    //             setFastChecked(allCheckedItems);

    //             // 
    //         } else {
    //             setFastChecked([]);
    //         }
    //     },
    //     [allChecked, fastList, dimension, fastSenderAddress, selectedFile, selectedPriceObject, insuranceFast, ddpFast, lucidFast, iossFast]
    // );

    return (
        <Box className="fast-shipment-table">
            <Table sx={{ backgroundColor: "white", width: "100%" }}>
                <TableHead sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px", backgroundColor: "white" }}>
                    <FastShipmentListHead allChecked={allChecked} handleSelectAll={handleToggleAll} />
                </TableHead>
                <TableBody sx={{ backgroundColor: "white", boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 10px", fontSize: "17px" }}>
                    {fastList.map((item, key) => (
                        <FastShipmentListItem profileSenderAddress={profileSenderAddress} setAllChecked={setAllChecked} key={key} item={item} allChecked={isAllChecked} />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default FastShipmentList;
