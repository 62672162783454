import { useState, useEffect } from "react";
import { Button, FormControl, FormHelperText, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import Iframe from "react-iframe";
import { validateEmail } from "../../utils/emailValidation";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    if (phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else {
      alert("Submitted");
    }
  };

  const isDisabled = () => {
    if (
      name === "" ||
      surname === "" ||
      email === "" ||
      phone === "" ||
      subject === "" ||
      message === ""
    ) {
      return true;
    } else return false;
  };

  const [emailError, setEmailError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  return (
    <div className="contact-form">
      <div className="form-wrapper">
        <div className="form-header">
          <h4>İletişim Formu</h4>
          <p>
            Sorularınız, önerileriniz ve merak ettikleriniz için lütfen bizimle
            iletişime geçin.
          </p>
        </div>
        <form className="form-container" onSubmit={handleContactFormSubmit}>
          <div className="form-item">
            <TextField
              size="small"
              required
              fullWidth
              label="Adınız"
              autoComplete="off"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              size="small"
              required
              fullWidth
              label="Soyadınız"
              autoComplete="off"
              type="text"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="form-item">
            <TextField
              value={email}
              required
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError && "Geçerli bir email giriniz."}
              size="small"
              label="E-Posta"
              fullWidth
              type="email"
              autoComplete="off"
            />
            <FormControl size="small" fullWidth>
              <PhoneInput
                country={"tr"}
                value={phone}
                onChange={(e) => setPhone(e)}
                autoComplete="off"
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                inputProps={{
                  name: "phone",
                  autoFocus: true,
                  required: true,
                  id: "ind-phone-input",
                  style: {
                    height: "100%",
                    width: "100%",
                    minHeight: "37.13px",
                    maxHeight: "37.13px",
                    borderColor: phoneIsNotValid
                      ? "var(--error)"
                      : "var(--inputBorderColor)",
                  },
                }}
              />
              {phoneIsNotValid && (
                <FormHelperText sx={{ color: "var(--error)" }}>
                  Telefon numaranızı giriniz.
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <TextField
            size="small"
            required
            fullWidth
            label="Konu Başlığı"
            autoComplete="off"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            multiline
            rows={8} 
            size="small"
            required
            fullWidth
            label="Mesajınız"
            autoComplete="off"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              className="iyzi-button-blue"
              disabled={isDisabled()}
              sx={{ width: "100%", ":disabled": { opacity: 0.4 } }}
            >
              Gönder
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
