import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Tooltip,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  DialogContentText,
  DialogActions
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import {
  fastCheckedState,
  fastDimensionState,
  fastLimitState,
  fastListState,
  fastPricesState,
  fastSenderAddressesState,
  fastShipmentCurrentPageState,
  fastShipmentTotalPagesState,
  portalState,
  openPackageListSaveState,
  shopIDState,
  shopNameState,
  countryState,
  suppliersState,
  receiptIDState,
  buyerEmailState,
  receiverNameState,
  formattedAddressState,
  statusState,
  parcelRefNoState,
  receiptDateStartState,
  receiptDateEndState,
  createDateStartState,
  createDateEndState,
  receiptDateState,
  createdDateState,
  dateTypeState,
  parcelTypeState,
  selectedFilesIntState,
  fileChangeState,
} from "../../atoms/fastShipment";
import { exchangeRateState } from "../../atoms/createShipment";
import {
  addressLoadingState,
  checkedSenderAddressIdState,
  senderCurrPageState,
  senderTotalPageState,
} from "../../atoms/membership";
import {
  FormatFieldWithInteger,
  FormatFieldWithTwoDecimals,
  FormatFieldWithTwoDecimalsWithMaxNumber,
} from "../../utils/FormatField";
import iyziWeightRoundSystem from "../../utils/iyziWeightRoundSystem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FastDimension from "./fastShipmentListItem/FastDimension";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import { toast } from "react-toastify";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Errors from "../../utils/Errors";
import FastShipmentSearch from "./FastShipmentSearch";
import { integrationsState } from "../../atoms/membership";
import AllSendDialog from "./AllSendDialog";
import CreateFastIOSSNumber from "./fastShipmentListItem/CreateFastIOSSNumber";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import InsuranceSlideWarning from "../createShipment/shipmentPrice/InsuranceSlideWarning";
import FastShipmentListHead from "./fastShipmentSearch/FastShipmentListHead";
import { currentIngtegrationsState } from "../../atoms/integrations";
import uploadIcon from "../../img/icons/upload_icon.svg";
import { useDropzone } from "react-dropzone";
import DowloadPdfView from "../dowloadPdfView/DowloadPdfView";
import PackageListItemInfo from "./fastShipmentListItem/PackageListItemInfo";
import countries from "../../json/countries.json";
import Currency from "../../utils/CurrencySymbol";
import iossCountries from "../../json/iossCountries.json";

const FastShipmentList = () => {
  const portal = useRecoilValue(portalState);
  const fastLimit = useRecoilValue(fastLimitState);
  const openPackageListSave = useRecoilValue(openPackageListSaveState);
  const integrations = useRecoilValue(integrationsState);

  const [fastChecked, setFastChecked] = useRecoilState(fastCheckedState);
  const [allChecked, setAllChecked] = useState(false);
  const [fastList, setFastList] = useRecoilState(fastListState);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [uploadFileExcelModal, setUploadFileExcelModal] = useState(false);
  const [sendFiles, setSendFiles] = useRecoilState(selectedFilesIntState);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isAbove150EURCheck, setIsAbove150EURCheck] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const exchangeRate = useRecoilValue(exchangeRateState);
  const dataID = fastList?.map((item) => item.id);
  const [fastShipmentTotalPages, setFastShipmentTotalPages] = useRecoilState(
    fastShipmentTotalPagesState
  );
  const [fastShipmentCurrentPage, setFastShipmentCurrentPage] = useRecoilState(
    fastShipmentCurrentPageState
  );

  const member = JSON.parse(localStorage.getItem("user"));
  const memberId = member?.user?.user_member?.id;

  const setFastSenderAddresses = useSetRecoilState(fastSenderAddressesState);
  const setIntegrations = useSetRecoilState(integrationsState);
  const setFastPrices = useSetRecoilState(fastPricesState);
  const setExchangeRate = useSetRecoilState(exchangeRateState);

  const [dataRendered, setDataRendered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [isDimensionExist, setIsDimensionExist] = useState(true);
  const [allSendDialog, setAllSendDialog] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalList, setErrorModalList] = useState([]);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [shopID, setShopID] = useRecoilState(shopIDState);
  const [shopName, setShopName] = useRecoilState(shopNameState);
  const [fileChange, setFileChange] = useRecoilState(fileChangeState);
  const [country, setCountry] = useRecoilState(countryState);
  const [supplier, setSupplier] = useRecoilState(suppliersState);
  const [receiptID, setReceiptID] = useRecoilState(receiptIDState);
  const [buyerEmail, setBuyerEmail] = useRecoilState(buyerEmailState);
  const [receiverName, setReceiverName] = useRecoilState(receiverNameState);
  const [formattedAddress, setFormattedAddress] = useRecoilState(
    formattedAddressState
  );
  const [status, setStatus] = useRecoilState(statusState);
  const [parcelRefNo, setParcelRefNo] = useRecoilState(parcelRefNoState);
  const [receiptDateStart, setReceiptDateStart] = useRecoilState(
    receiptDateStartState
  );
  const [receiptDateEnd, setReceiptDateEnd] =
    useRecoilState(receiptDateEndState);
  const [createDateStart, setCreateDateStart] =
    useRecoilState(createDateStartState);
  const [createDateEnd, setCreateDateEnd] = useRecoilState(createDateEndState);
  const [receiptDate, setReceiptDate] = useRecoilState(receiptDateState);
  const [createdDate, setCreatedDate] = useRecoilState(createdDateState);
  const [dateType, setDateType] = useRecoilState(dateTypeState);
  const [parcelType, setParcelType] = useRecoilState(parcelTypeState);
  const [addressLoading, setAddressLoading] = useRecoilState(addressLoadingState);

  const [profileSenderAddress, setProfileSenderAddress] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const setCheckedSenderAddressId = useSetRecoilState(
    checkedSenderAddressIdState
  );

  const setSenderCurrPage = useSetRecoilState(senderCurrPageState);
  const setSenderTotalPage = useSetRecoilState(senderTotalPageState);

  const [currentIngtegrations, setCurrentIntegrations] = useRecoilState(
    currentIngtegrationsState
  );
  const [integrationListed, setIntegrationListed] = useState(false);

  const [columnType, setColumnType] = useState("");
  const [orderDirection, setOrderDirection] = useState("");

  const [openFastDimension, setOpenFastDimension] = useState(false);
  const [openFastSupplier, setOpenFastSupplier] = useState(false);
  const [openFastSupplierCountryError, setOpenFastSupplierCountryError] = useState(false);
  const [openFastProforma, setOpenFastProforma] = useState(false);
  const [openFastDimensionUpdate, setOpenFastDimensionUpdate] = useState(false);
  const [openFastDimensionNew, setOpenFastDimensionNew] = useState(false);
  const [fastDimension, setFastDimension] = useRecoilState(fastDimensionState);

  const handleClose = useCallback(() => {
    setLoading(false);
    setOpenFastDimension(false);
    setOpenFastDimensionUpdate(false);
  }, [setOpenFastDimensionNew]);

  const handleCloseFastSupplier = useCallback(() => {
    setOpenFastSupplier(false);
    setSupplier("");
  }, [setOpenFastSupplier]);

  const handleCloseFastSupplierCountryError = useCallback(() => {
    setOpenFastSupplierCountryError(false);
    setSupplier("");
  }, [setOpenFastSupplierCountryError]);

  const handleCloseProforma = () => {
    setLoading(false);
    setOpenFastProforma(false);
    setProformaRows([]);
  };

  const [en, setEn] = useState("");
  const [boy, setBoy] = useState("");
  const [yuk, setYuk] = useState("");
  const [kg, setKg] = useState("");
  const [qty, setQty] = useState("");
  const [enNew, setEnNew] = useState("");
  const [boyNew, setBoyNew] = useState("");
  const [yukNew, setYukNew] = useState("");

  const [ioss, setIoss] = useState("");
  const [parcelTypeChanges, setParcelTypeChanges] = useState("");
  const [currencyChanges, setCurrencyChanges] = useState("");
  const [productCountryChanges, setProductCountryChanges] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [productSku, setProductSku] = useState("");
  const [productHscode, setProductHscode] = useState("");
  const [hsCodeError, setHsCodeError] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [kgNew, setKgNew] = useState("");
  const [qtyNew, setQtyNew] = useState(1);
  const [totalCw, setTotalCw] = useState(0);
  const [proformaRows, setProformaRows] = useState([]);

  const [dimensions, setDimensions] = useState([
    {
      en: "",
      boy: "",
      yuk: "",
      gross: "",
      qty: "1",
      status: "",
    },
  ]);

  const handleIossChange = (event) => {
    const selectedValue = event.target.value; // Seçilen değeri al
    setIoss(selectedValue); // State'i güncelle
  };

  const handleParcelTypeChange = (event) => {
    const selectedValue = event.target.value; // Seçilen değeri al
    setParcelTypeChanges(selectedValue); // State'i güncelle
  };

  const handleCurrencyChange = (event) => {
    const selectedValue = event.target.value; // Seçilen değeri al
    setCurrencyChanges(selectedValue); // State'i güncelle
  };

  const handleHSCodeBlur = (e) => {
    const value = e.target.value;
    const inputLength = value.length;

    if (inputLength < 6 && inputLength !== 0) {
      setHsCodeError(true);
    } else {
      setHsCodeError(false);
    }

    if (inputLength >= 6 && inputLength <= 12) {
      // Eksik kalan haneleri 0 ile tamamlayıp state'e set ediyoruz
      setProductHscode(value + "0".repeat(12 - inputLength));
    }
  };

  const handleHSCodeBlurRows = (e, index) => {
    const value = e.target.value;
    const inputLength = value.length;

    if (inputLength < 6 && inputLength !== 0) {
      setHsCodeError(true);
    } else {
      setHsCodeError(false);
    }

    if (inputLength >= 6 && inputLength <= 12) {
      // Eksik kalan haneleri 0 ile tamamlayıp state'e set ediyoruz
      setProformaRows((prevRows) =>
        prevRows.map((item, i) =>
          i === index
            ? { ...item, hs_code: value + "0".repeat(12 - inputLength) }
            : item
        )
      );
    }
  };

  const handleChangeDimension = (index) => (e) => {
    const selectedDimensionId = e.target.value;
    const selectedDimension = fastDimension.find(
      (dim) => dim.id === selectedDimensionId
    );

    setDimensions((prevDimensions) => {
      const newDimensions = [...prevDimensions];
      if (selectedDimension) {
        newDimensions[index] = {
          ...newDimensions[index],
          en: selectedDimension.width,
          boy: selectedDimension.length,
          yuk: selectedDimension.height,
          gross: selectedDimension.gross,
          qty: "1",
        };
      } else {
        // Clear values if no dimension is selected
        newDimensions[index] = {
          ...newDimensions[index],
          en: "",
          boy: "",
          yuk: "",
          gross: "",
          qty: "1",
        };
      }

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(newDimensions));

      return newDimensions;
    });
  };

  const handleDecimalChange = (index, name) => (e) => {
    const value = e.target.value.replace(/,/g, "."); // Convert commas to dots

    setDimensions((prevDimensions) => {
      const newDimensions = [...prevDimensions];
      newDimensions[index][name] = value;

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(newDimensions));

      return newDimensions;
    });

    if (name === "en") {
      FormatFieldWithTwoDecimals(e, setEn);
    } else if (name === "boy") {
      FormatFieldWithTwoDecimals(e, setBoy);
    } else if (name === "yuk") {
      FormatFieldWithTwoDecimals(e, setYuk);
    } else if (name === "gross") {
      FormatFieldWithTwoDecimalsWithMaxNumber(e, setKg, 999);
    } else if (name === "qty") {
      FormatFieldWithInteger(e, setQty);
    }
  };

  const calculateTotalCw = (dimensions) => {
    return dimensions.reduce((acc, dim) => {
      const grossWeight = parseFloat(dim.gross || 0) * parseFloat(dim.qty || 1);
      const volumetricWeight =
        ((Math.ceil(Number(dim.width || dim.en)) *
          Math.ceil(Number(dim.length || dim.boy)) *
          Math.ceil(Number(dim.height || dim.yuk))) /
          5000) *
        parseFloat(dim.qty || 1);

      const chargeableWeight = Math.max(grossWeight, volumetricWeight);
      return acc + chargeableWeight;
    }, 0);
  };

  const handleRemoveInput = (index) => {
    setDimensions((prevDimensions) => {
      const newDimensions = [...prevDimensions];
      newDimensions.splice(index, 1);

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(newDimensions));

      return newDimensions;
    });
  };

  const handleAddInput = () => {
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    const newDimension = {
      id: null,
      en: "",
      boy: "",
      yuk: "",
      gross: "",
      qty: "1",
      status: "new",
    };
    setDimensions((prevDimensions) => {
      const updatedDimensions = [...prevDimensions, newDimension];

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(updatedDimensions));

      return updatedDimensions;
    });
  };

  const handleCloseNew = useCallback(() => {
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setQty("");
    setLoading(false);
    setOpenFastDimensionNew(false);
  }, [setOpenFastDimension]);

  const handleAddNewDimension = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      length: enNew,
      width: boyNew,
      height: yukNew,
      gross: kgNew,
      qty: qtyNew,
    };

    try {
      const response = await axios.post("member-dimension/add", data);
      toast.success(response.data.message);
      setFastDimension((prevDimensions) => [
        response.data.data,
        ...prevDimensions,
      ]);
      setOpenFastDimensionNew(false);
    } catch (error) {
      Errors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecimalChangeNew = (e) => {
    const { name } = e.target;

    if (name === "en") {
      FormatFieldWithTwoDecimals(e, setEnNew);
    } else if (name === "boy") {
      FormatFieldWithTwoDecimals(e, setBoyNew);
    } else if (name === "yuk") {
      FormatFieldWithTwoDecimals(e, setYukNew);
    } else if (name === "gross") {
      FormatFieldWithTwoDecimalsWithMaxNumber(e, setKgNew, 999);
    } else if (name === "qty") {
      FormatFieldWithInteger(e, setQtyNew);
    }
  };

  const [activeSort, setActiveSort] = useState([
    { column: "platform", direction: null },
    { column: "shop_name", direction: null },
    { column: "receipt_id", direction: null },
    { column: "country", direction: null },
    { column: "contact_name", direction: null },
    { column: "status", direction: null },
    { column: "parcel_ref_no", direction: null },
    { column: "supplier_id", direction: null },
    { column: "receipt_date", direction: null },
  ]);

  const handleErrorModal = () => {
    setErrorModalOpen(false);
    setErrorModalList([]);
    setErrorModalMessage();
    window.location.reload();
  };

  const fastShipmentList = useCallback(async () => {
    setLoading(true);
    await axios
      .get("general/integration-parcel", {
        params: {
          limit: 20,
          order_by: "receipt_date",
          detailed: 1,
          is_excel: window.location.href.endsWith("create-fast-all-shipment")
            ? 1
            : 0,
        },
      })
      .then((response) => {
        // Gelen datanın her bir öğesine isPriceTake: '0' ekle
        const modifiedData = response.data.data.map((item) => ({
          ...item,
          isPriceTake: "0",
        }));

        setFastList(modifiedData);
        setDataRendered(true);
        setFastShipmentTotalPages(response.data.totalPages);
        setFastShipmentCurrentPage(response.data.currentPage);
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  }, [
    portal,
    fastLimit,
    setFastList,
    setFastShipmentTotalPages,
    setFastShipmentCurrentPage,
  ]);

  const fastSenderAddressList = useCallback(() => {
    axios
      .post(`member-address/list`, {
        address_type: 4,
        country: "TR",
      })
      .then((response) => {
        setFastSenderAddresses(response.data.data);
      })
      .catch((error) => Errors(error));
  }, [setFastSenderAddresses]);

  const dimensionList = useCallback(() => {
    axios
      .get("member-dimension")
      .then((response) => {
        setFastDimension(response.data.data);
        if (response.data.data.length === 0) {
          setIsDimensionExist(false);
        } else {
          setIsDimensionExist(true);
        }
      })
      .catch((error) => Errors(error));
  }, [setFastDimension]);

  const getIossList = useCallback(async () => {
    axios.get(`member-ioss-number?limit=999`).then((response) => {
      setIntegrations(response.data.data);
    });
  }, [setIntegrations]);

  const handlePageChangeManuel = async (column, pageValue) => {
    setLoading(true);
    setFastShipmentCurrentPage(pageValue);
    setFastChecked([]);
    const updatedSort = activeSort.map((sort) => {
      if (sort.column === column) {
        return {
          ...sort,
          direction: sort.direction === "ASC" ? "DESC" : "ASC",
        };
      }
      return {
        ...sort,
        direction: null, // Diğer kolonların sıralama yönünü sıfırla
      };
    });

    // Kolon sıralama durumunu kontrol et
    let sortExists = updatedSort.find((sort) => sort.column === column);

    // Eğer yoksa, default direction ile ekle
    if (!sortExists) {
      sortExists = { column: column, direction: "ASC" };
      updatedSort.push(sortExists);
    }

    setActiveSort(updatedSort);
    setLoading(true); // Request öncesi loading state'ini ayarla

    try {
      const response = await axios.get("general/integration-parcel", {
        params: {
          detailed: 1,
          limit: 20,
          page: pageValue,
          order_direction: orderDirection,
          order_by: columnType ? columnType : "receipt_date",
          is_excel: window.location.href.endsWith("create-fast-all-shipment")
            ? 1
            : 0,
          ...(portal ? { portal } : {}), // Portal boş değilse ekle
          ...(shopID ? { platform: shopID } : {}), // Add shop_id if it's not empty
          ...(shopName ? { shop_name: shopName } : {}), // Add shop_id if it's not empty
          ...(country ? { country: country } : {}), // Add shop_id if it's not empty
          ...(supplier ? { supplier_id: supplier } : {}), // Add shop_id if it's not empty
          ...(receiptID ? { receipt_id: receiptID } : {}), // Add shop_id if it's not empty
          ...(buyerEmail ? { buyer_email: buyerEmail } : {}), // Add buyer_email if it's not empty
          ...(receiverName ? { contact_name: receiverName } : {}), // Add contact_name if it's not empty
          ...(formattedAddress ? { formatted_address: formattedAddress } : {}), // Add formatted_address if it's not empty
          ...(status ? { status: status } : {}), // Add formatted_address if it's not empty
          ...(parcelRefNo ? { parcel_ref_no: parcelRefNo } : {}), // Add formatted_address if it's not empty
          ...(dateType ? { date_type: dateType } : {}), // Add formatted_address if it's not empt
          ...(receiptDateStart ? { start_date: receiptDateStart } : {}),
          ...(receiptDateEnd ? { end_date: receiptDateEnd } : {}),
          ...(createDateStart ? { start_date: createDateStart } : {}),
          ...(createDateEnd ? { end_date: createDateEnd } : {}),
          ...(parcelType ? { parcel_type: parcelType } : {}),
        },
      });

      setFastList(response.data.data);
      setDataRendered(true);
      setFastShipmentTotalPages(response.data.totalPages);
      setFastShipmentCurrentPage(pageValue);
    } catch (error) {
      Errors(error);
    } finally {
      setLoading(false);
    }
  };

  const allFastShipmentSend = () => {
    setFileChange(true);
    if (fastChecked.length === 0) {
      return;
    } else {
      setBulkLoading(true);
      const formData = new FormData();

      fastChecked.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          if (key === "commoduties") {
            // commoduties dizisi için
            item[key].forEach((commodity, commodityIndex) => {
              Object.entries(commodity).forEach(
                ([commodityKey, commodityValue]) => {
                  formData.append(
                    `items[${index}][${key}][${commodityIndex}][${commodityKey}]`,
                    commodityValue
                  );
                }
              );
            });
          } else if (key === "files") {
            // Dosyaları işle ve FormData'ya ekle
            item[key].forEach((file, fileIndex) => {
              if (file instanceof File) {
                formData.append(
                  `items[${index}][files][${fileIndex}]`,
                  file,
                  file.name
                );
              }
            });
          } else if (typeof item[key] === "object" && item[key] !== null) {
            // Diğer iç içe objeler veya diziler için
            Object.entries(item[key]).forEach(([subKey, subValue]) => {
              if (typeof subValue === "object" && subValue !== null) {
                // Daha derin iç içe objeler veya diziler için
                Object.entries(subValue).forEach(([subSubKey, subSubValue]) => {
                  formData.append(
                    `items[${index}][${key}][${subSubKey}]`,
                    subSubValue
                  );
                });
              } else {
                // Basit değerler için
                formData.append(`items[${index}][${key}][${subKey}]`, subValue);
              }
            });
          } else {
            // Basit anahtar-değer çiftleri için
            formData.append(`items[${index}][${key}]`, item[key]);
          }
        });
      });

      axios
        .post("general/create-parcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const createdParcels = response.data.data.map((parcel) => ({
            id: parcel.id,
            parcelRefNo: parcel.get_parcel.parcel_ref_no,
            priceStatus: 1,
            additionalText: "Oluşturuldu",
            isPriceTake: 1,
          }));

          setFastList((prevList) => {
            const updatedList = prevList.map((fast) => {
              const createdParcel = createdParcels.find(
                (parcel) => parcel.id === fast.id
              );
              if (createdParcel) {
                return {
                  ...fast,
                  ...createdParcel,
                };
              }
              return fast;
            });
            return updatedList;
          });
          setFastChecked([]);
          setAllChecked(false);
          setFastPrices((prevPrices) => {
            const updatedPrices = prevPrices.map((price) => {
              const createdParcel = createdParcels.find(
                (parcel) => parcel.id === price.id
              );
              if (createdParcel) {
                return {
                  ...price,
                  priceStatus: 1,
                };
              }
              return price;
            });
            return updatedPrices;
          });

          toast.success(response.data.message);
        })
        .catch((error) => {
          Errors(error); // Hata yönetimi fonksiyonunu çağır
          if (error.response.data.status === 400) {
            setErrorModalOpen(true);
            setErrorModalList(error.response.data.data);
            setErrorModalMessage(error.response.data.message);
          }
        })
        .finally(() => {
          setBulkLoading(false);
          setFileChange(false);
        });
    }
  };

  const allFastShipmentDialog = () => {
    setFileChange(true);
    setAllSendDialog(true);
  };
  const handleDimensionModal = () => {
    setOpenFastDimension(true);
  };
  const handleSupplierModal = () => {
    if (!fastChecked.every(item => item.receiver_country === fastChecked[0].receiver_country) || !fastChecked.every(item => item.parcel_type === fastChecked[0].parcel_type)) {
      setOpenFastSupplierCountryError(true);
    } else {
      setOpenFastSupplier(true);
    }
  };

  const calculateTotalPrice = () => {
    // Önce her bir öğenin get_commoduties[0]'a sahip olup olmadığını kontrol edelim
    const allHaveCommodities = fastChecked.every(item => item.get_commoduties?.[0]);

    if (!allHaveCommodities) {
      console.error("Some items do not have commodities.");
      setTotalPrice(0); // Eğer bir öğe eksikse toplam fiyatı 0 olarak ayarla
      return;
    }

    // Toplam fiyatı hesapla
    const total = fastChecked.reduce((accumulator, item) => {
      const product = item.get_commoduties[0]; // İlk commodity'yi al
      return accumulator + (Number(product.quantity) * Number(product.price));
    }, 0);

    setTotalPrice(total); // Toplam fiyatı state'e kaydet
  };

  const calculateIsAbove150EUR = () => {
    // Find the EUR exchange rate
    const euroRate = exchangeRate?.find(rate => rate.currency === "EUR")?.sell_rate;

    let convertedValue = totalPrice;

    // Check if the currency is USD or GBP, then convert it to EUR
    if (fastChecked.every(item => item.get_commoduties?.[0]?.currency) === "USD") {
      const usdRate = exchangeRate?.find(rate => rate.currency === "USD")?.sell_rate;
      convertedValue = (convertedValue * usdRate) / euroRate;
    } else if (fastChecked.every(item => item.get_commoduties?.[0]?.currency) === "GBP") {
      const gbpRate = exchangeRate?.find(rate => rate.currency === "GBP")?.sell_rate;
      convertedValue = (convertedValue * gbpRate) / euroRate;
    } else if (fastChecked.every(item => item.get_commoduties?.[0]?.currency) === "EUR") {
      // If already in EUR, just use the convertedValue directly
      convertedValue = convertedValue;
    }

    // Return true if the converted value is greater than 150 EUR, false otherwise
    return convertedValue >= 150;

  };

  const isButtonDisabled = (supplierId) => {
    // ioss kontrolünü sadece supplier 302 ve 338 için yapıyoruz
    const isSupplier302Or338 = supplierId === 302 || supplierId === 338;
    const isIossInvalid = fastChecked.every(item => !item.ioss || item.ioss.length < 5);

    const isParcelType3AndSupplier = fastChecked.every(item => item.parcel_type === "3") && (
      supplierId === 701 || supplierId === 801 || supplierId === 901 || supplierId === 347 || supplierId === 306);

    const isParcelTypeNot3AndSupplier336 = fastChecked.every(item => item.parcel_type !== "3") && supplierId === 336;

    if (isSupplier302Or338 && isIossInvalid) {
      return true;
    }

    if (isSupplier302Or338 && !isIossInvalid) {
      return false;
    }

    if (isParcelType3AndSupplier) {
      return true;
    }

    if (isParcelTypeNot3AndSupplier336) {
      return true;
    }

    return false;
  };

  const handleProformaModal = () => {
    setOpenFastProforma(true);
  };
  const handleUploadFileModal = () => {
    setUploadFileModal(true);
  };
  const handleUploadFileExcelModal = () => {
    setUploadFileExcelModal(true);
  };
  const handleUploadFileExcelModalClose = () => {
    setUploadFileExcelModal(false);
  };

  const handleEtsyIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "ETSY",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("Etsy siparişleriniz güncellendi!");
          setLoading(false);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleShopifyIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "Shopify",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("Shopify siparişleriniz güncellendi!");
          setLoading(true);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleWooCommerceIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "WooCommerce",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("WooCommerce siparişleriniz güncellendi!");
          setLoading(true);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleJbuyIntegration = () => {
    setLoading(true);
    axios
      .post("jbuy/store-orders")
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("JBUY siparişleriniz güncellendi!");
          setLoading(true);
        }
      })
      .catch((error) => Errors(error));
  };

  const handleEbayIntegration = () => {
    setLoading(true);
    axios
      .post("member/update-integration-shops", {
        portal: "Ebay",
      })
      .then((response) => {
        if (response.status === 200) {
          fastShipmentList();
          toast.success("Ebay siparişleriniz güncellendi!");
          setLoading(false);
        }
      })
      .catch((error) => Errors(error));
  };

  const getAddresses = async () => {
    setAddressLoading(true);

    const [senderResponse] = await Promise.all([
      axios.get("member-address", { params: { address_type: 4, limit: 20 } }),
    ]).finally(() => {
      setAddressLoading(false);
    });

    setProfileSenderAddress(senderResponse.data.data);
    setCheckedSenderAddressId(
      senderResponse.data.data.find((e) => e.is_default === "1")?.id
    );
    setSenderCurrPage(senderResponse.data.currentPage);
    setSenderTotalPage(senderResponse.data.totalPages);
  };

  useEffect(() => {
    fastShipmentList();
    fastSenderAddressList();
    dimensionList();
    getIossList();
    getAddresses();
  }, [fastShipmentList, fastSenderAddressList, dimensionList, getIossList]);

  useEffect(() => {
    if (openPackageListSave) {
      fastShipmentList();
    }
  }, [openPackageListSave, fastShipmentList]);

  const getData = useCallback(() => {
    axios
      .get(`general/integration-list`)
      .then((response) => {
        setCurrentIntegrations(response.data.data);
        setIntegrationListed(true);
      })
      .catch((error) => Errors(error));
  }, [setCurrentIntegrations]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handUploadFileModal = () => {
    setUploadFileModal(false);
    setSendFiles([]);
    setSelectedFiles([]);
  };

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      // Her bir dosya için bir nesne oluştur ve diziye ekle
      const newFiles = acceptedFiles.map((file) => ({
        file,
        id: Math.random().toString(), // Her dosya için benzersiz bir ID oluştur
      }));

      // Mevcut dosyaları koruyarak yeni dosyaları ekle
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const { getRootProps: getRootPropsCSV, getInputProps: getInputPropsCSV } =
    useDropzone({
      onDrop: handleDrop,
      multiple: false,
      accept: {
        "application/pdf": [".csv"],
      },
    });

  const { getRootProps: getRootPropsExcel, getInputProps: getInputPropsExcel } =
    useDropzone({
      onDrop: handleDrop,
      multiple: false,
      accept: {
        "application/pdf": [".xlsx"],
      },
    });

  const handleUploadModalExcel = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (selectedFiles) {
        formData.append("excel_file", selectedFiles[0].file);
      }

      const response = await axios.post(
        "general/add-order-to-excel",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Dosya yükleme başarılı.", { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
      handUploadFileModal();
      setLoading(false);
    } catch (error) {
      toast.error("Dosya yükleme başarısız.", { autoClose: 2000 });
      setLoading(false);
    }
  };

  console.log(selectedFiles);

  const handleUploadModal = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (selectedFiles) {
        formData.append('csv_file', selectedFiles[0].file);
      }

      const response = await axios.post('/etsy/read-csv-orders', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success("Dosya yükleme başarılı.", { autoClose: 2000 });
      
      handUploadFileModal();
      setLoading(false);
    } catch (error) {
      toast.error("Dosya yükleme başarısız.", { autoClose: 2000 });
      setLoading(false);
    }
  };

  const handleAddNewDimensionManuel = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFileChange(true);

    const dimensionsData = fastChecked.flatMap((item) =>
      dimensions.map((dimension) => ({
        integration_parcel_id: item.integration_parcel_id,
        dimension_id: 0,
        length: dimension.boy || dimension.length,
        width: dimension.en || dimension.width,
        height: dimension.yuk || dimension.height,
        gross: dimension.gross || dimension.gross,
        qty: dimension.qty,
        vw: dimension.vw || "1",
        gw: dimension.gw || "1",
        cw: dimension.cw || "1",
        ds: dimension.ds || "1",
      }))
    );

    try {
      const response = await axios.post("general/add-dimensions", {
        dimensions: dimensionsData,
      });
      toast.success(response.data.message);

      const updatedDimensionsData = response.data.data;

      if (Array.isArray(updatedDimensionsData)) {
        setFastList((prevList) => {
          return prevList.map((item) => {
            const updatedItem = updatedDimensionsData.find(
              (updated) => updated.id === item.id
            );
            if (updatedItem) {
              return {
                ...item,
                get_dimensions: updatedItem.get_dimensions,
              };
            }
            return item;
          });
        });

        setFastChecked((prevList) => {
          return prevList.map((item) => {
            const updatedItem = updatedDimensionsData.find(
              (updated) => updated.id === item.id
            );
            if (updatedItem) {
              return {
                ...item,
                dimensionList: updatedItem.get_dimensions,
              };
            }
            return item;
          });
        });

        setDimensions(
          updatedDimensionsData.map((dim) => ({
            ...dim,
            status: "",
          }))
        );
      } else {
        setDimensions([]);
      }
    } finally {
      setLoading(false);
      handleClose();
      setDimensions([
        {
          en: "",
          boy: "",
          yuk: "",
          gross: "",
          qty: "1",
          status: "",
        },
      ]);
      setTotalCw(0);
      setFileChange(false);
    }
  };

  const handleAddSupplier = async (e) => {
    e.preventDefault();
    setSupplierLoading(true);

    const dimensionsData = [
      ...new Set(
        fastChecked.flatMap((item) =>
          dimensions.map(() => item.integration_parcel_id)
        )
      ),
    ];

    try {
      const response = await axios.get(`general/integration-parcel-price/1`, {
        params: {
          ids: [dimensionsData.join(',')], // Virgülle ayrılmış string
          price_supplier_id: Number(supplier),
          multiple: true,
        },
      });
      toast.success(response.data.message);

      const updatedDimensionsData = response.data.data;

      if (Array.isArray(updatedDimensionsData)) {
        setFastList((prevList) =>
          prevList.map((item) => {
            const updatedItem = updatedDimensionsData.find(
              (updated) => updated.id === item.id
            );
            return updatedItem
              ? {
                ...item,
                prices: updatedItem.prices, // Sadece prices alanını güncelle
                supplier_id: supplier,
                isPriceTake: "1",
              }
              : item;
          })
        );
      }

    } finally {
      setSupplierLoading(false);
      setOpenFastSupplier(false);
      setFastChecked([]);
    }
  };

  const handleAddNewDimensionUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedDimensions = fastChecked.flatMap((item) =>
      item.dimensionList.map((dimensionId) =>
        dimensions
          .filter((dim) => dim.status === "new" || dim.status === "")
          .map((dim) => ({
            dimension_id: dim.status === "new" ? 0 : dimensionId.dimension_id, // Use the dimension_id from the dimensionList
            integration_parcel_id: item.integration_parcel_id, // Ensure integration_parcel_id is correctly taken from fastChecked
            length: dim.boy || dim.length,
            width: dim.en || dim.width,
            height: dim.yuk || dim.height,
            gross: dim.gross || dim.gross,
            qty: dim.qty,
            vw: "1",
            gw: "1",
            cw: "1",
            ds: "1",
          }))
      )
    );

    try {
      const response = await axios.post(`general/update-dimensions`, {
        dimensions: updatedDimensions.flat(),
      });

      toast.success("Ürün ölçüleri güncellendi.");

      // Extract the updated items from the response
      const updatedItems = response.data.data;

      // Update the get_dimensions field of the corresponding items in the fastList
      setFastList((prevList) => {
        return prevList.map((item) => {
          const updatedItem = updatedItems.find(
            (updated) => updated.id === item.id
          );
          if (updatedItem) {
            return {
              ...item,
              get_dimensions: updatedItem.get_dimensions, // Update only the get_dimensions field
            };
          }
          return item;
        });
      });
    } catch (error) {
      toast.error(error.data.message);
    } finally {
      setLoading(false);
      handleClose();
      setDimensions([
        {
          en: "",
          boy: "",
          yuk: "",
          gross: "",
          qty: "1",
          status: "",
        },
      ]);
      setTotalCw(0);
    }
  };

  const getCurrency = () => {
    axios
      .get(`exchange-rate`)
      .then((response) => {
        setExchangeRate(response.data.data);
      })
      .catch((error) => {
        Errors(error);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const message = () => {
    if (hsCodeError) {
      return "Hs Kodu 6 haneden küçük girilemez ve tüm döviz cinsleri aynı olmalıdır.";
    } else if (hsCodeError) {
      return "Hs Kodu 6 haneden küçük girilemez.";
    }
  };

  const handleEditSubmit = async () => {
    if (!hsCodeError) {
      setLoading(true);
      setFileChange(true);

      // Initialize the FormData object
      const formData = new FormData();

      // Commodities from fastChecked
      const commodutiesFromFastChecked = fastChecked.flatMap((item, index) => {
        if (item.commoduties && Array.isArray(item.commoduties)) {
          return item.commoduties.map((commodity, commodityIndex) => {
            const commodutyData = {
              commoduty_id: commodity.id || "",
              integration_parcel_id: Number(commodity.integration_parcel_id),
              title: productTitle || commodity.title,
              sku: productSku && productSku !== "null" ? productSku : commodity.sku && commodity.sku !== "null" ? commodity.sku : "",
              parcel_type: parcelTypeChanges || commodity.parcel_type,
              country: productCountryChanges || commodity.country,
              quantity: productQuantity || commodity.qty,
              hs_code: productHscode || commodity.hs_code,
              price: Number(productPrice) || Number(commodity.price),
              currency: currencyChanges || commodity.currency,
              total_price: Number(productPrice) || Number(commodity.total_price),
              ioss: ioss === "null" || ioss == null ? "" : ioss,
            };

            // Append commodutyData fields to FormData
            Object.keys(commodutyData).forEach((key) => {
              formData.append(`commoduties[${index * 100 + commodityIndex}][${key}]`, commodutyData[key]); // Ensure unique index
            });

            // Append files to FormData for each commodity
            sendFiles.forEach((file, fileIndex) => {
              formData.append(`commoduties[${index * 100 + commodityIndex}][files][${fileIndex}]`, file.file);
            });

            return commodutyData;
          });
        }
        return [];
      });

      // Commodities from proformaRows
      const commodutiesFromProformaRows = proformaRows.map((row, rowIndex) => {
        const commodutyData = {
          commoduty_id: row.commoduty_id || "",
          integration_parcel_id: row.integration_parcel_id,
          title: row.title || "",
          sku: row.sku && row.sku !== "null" ? row.sku : "",
          parcel_type: row.parcel_type || "",
          country: row.country || "",
          quantity: row.qty || "",
          hs_code: row.hs_code || "",
          price: Number(row.price) || "",
          currency: row.currency || "",
          total_price: Number(row.total_price) || "",
          ioss: row.ioss || "",
        };

        // Append commodutyData fields to FormData
        Object.keys(commodutyData).forEach((key) => {
          formData.append(`commoduties[${fastChecked.length * 100 + rowIndex}][${key}]`, commodutyData[key]); // Ensure unique index
        });

        return commodutyData;
      });

      // Combine both fastChecked and proformaRows commodities
      const commoduties = [...commodutiesFromFastChecked, ...commodutiesFromProformaRows];

      try {
        const response = await axios.post(`general/update-commoduty`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success(response.data.message);

        const updatedItems = response.data.data;

        setFastList((prevList) =>
          prevList.map((item) => {
            const updatedItem = updatedItems.find((updated) => updated.id === item.id);
            if (updatedItem) {
              return {
                ...item,
                updated_commoduty: updatedItem.updated_commoduty,
                get_commoduties: updatedItem.get_commoduties,
                get_integration_parcel_documents: updatedItem.get_integration_parcel_documents,
                parcel_type: updatedItem.parcel_type,
              };
            }
            return item;
          })
        );
      } catch (error) {
        Errors(error);
      } finally {
        setLoading(false);
        handleCloseProforma();
      }
    }
  };

  useEffect(() => {
    getCurrency();
  }, []);

  const handleDownloadExcelCopy = () => {
    const link = document.createElement("a");
    link.href = "/images/home/testexcel.xlsx";
    link.download = "testexcel.xlsx"; // You can specify the name of the file to be saved
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const handleChangeCommodity = (value, itemIndex, index, key) => {
  //   setFastChecked((prev) =>
  //     prev.map((item, i) =>
  //       i === itemIndex
  //         ? {
  //           ...item,
  //           commoduties: item.commoduties.map((comm, commIndex) =>
  //             commIndex === index ? { ...comm, [key]: value } : comm
  //           ),
  //         }
  //         : item
  //     )
  //   );
  // };

  // const handleAddNewProformaRow = () => {
  //   setProformaRows((prevRows) => [
  //     ...prevRows,
  //     ...fastChecked.map((item) => ({
  //       commoduty_id: "",
  //       integration_parcel_id: item.integration_parcel_id,
  //       title: "",
  //       sku: "",
  //       parcel_type: item.parcel_type,
  //       country: "",
  //       qty: "",
  //       hs_code: "",
  //       price: "",
  //       currency: item.commoduties[0].currency,
  //       total_price: item.commoduties[0].total_price,
  //       ioss: "",
  //     })),
  //   ]);
  // };


  // const handleRemoveProformaRow = (index) => {
  //   setProformaRows((prevRows) => prevRows.filter((_, i) => i !== index));
  // };

  const handleSelect = (id) => {
    setSupplier(id);
  };

  const getSuppliers = () => {
    axios.get("supplier").then((response) => {
      setSuppliers(response.data.data);
    });
  };

  useEffect(() => {
    getSuppliers();
  }, [openFastSupplier]);

  useEffect(() => {
    setFastChecked(fastChecked);
  }, [fastChecked]);

  useEffect(() => {
    calculateTotalPrice();
    calculateIsAbove150EUR();
  }, [fastChecked]);

  useEffect(() => {
    const isAbove150EUR = calculateIsAbove150EUR();
    setIsAbove150EURCheck(isAbove150EUR);
  }, [exchangeRate, fastChecked.every(item => item.get_commoduties?.[0]?.currency), totalPrice]);

  return (
    <Box className="fast-shipment-list">
      <InsuranceSlideWarning />
      <CreateFastIOSSNumber />
      <Modal open={openFastProforma} onClose={handleCloseProforma}>
        <div className="package-list">
          <PackageListItemInfo />
          <CancelOutlinedIcon className="iyzi-icon-close" onClick={handleCloseProforma} />
          <Box sx={{ fontSize: '20px', fontWeight: '500', paddingLeft: '5px' }}>
            Proforma Detayları
          </Box>
          <Box className="package-inputs">
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              className="package-inputs-first"
            >
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="select-product-description">
                  Gönderi Açıklaması
                </InputLabel>
                <Select
                  labelId="select-product-description"
                  id="select-simple-product-description"
                  value={parcelTypeChanges}
                  required
                  onChange={handleParcelTypeChange}
                  label="Gönderi Açıklaması"
                  name="parcel_type"
                >
                  <MenuItem value="">
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={1}>Normal Gönderi veya Numune</MenuItem>
                  <MenuItem value={2}>Hediye</MenuItem>
                  <MenuItem value={3}>
                    Mikro İhracat (ETGB'li) E-ihracat
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="select-currency">Döviz</InputLabel>
                <Select
                  labelId="select-currency"
                  id="select-simple-currency"
                  value={currencyChanges}
                  onChange={handleCurrencyChange}
                  label="Döviz"
                  name="currency"
                >
                  <MenuItem value="">
                    <em>Temizle</em>
                  </MenuItem>
                  <MenuItem value={"EUR"}>€</MenuItem>
                  <MenuItem value={"USD"}>$</MenuItem>
                  <MenuItem value={"GBP"}>£</MenuItem>
                </Select>
              </FormControl>
              {iossCountries.some((country) =>
                fastChecked.some((item) => item.receiver_address?.country === country.iso_code)
              ) && (
                  <FormControl
                    sx={{
                      width: "50%",
                      height: "40px",
                    }}
                  >
                    <InputLabel id="select-ioss">IOSS/VAT</InputLabel>
                    <Select
                      labelId="select-ioss"
                      id="select-simple-ioss"
                      value={ioss}
                      onChange={handleIossChange}
                      label="IOSS/VAT"
                      sx={{ height: "52px" }}
                    >
                      <MenuItem value="">
                        <em>Temizle</em>
                      </MenuItem>
                      {integrations?.map((integration) => (
                        <MenuItem
                          sx={{
                            display: "flex",
                            gap: "var(--gap)",
                            alignItems: "center",
                          }}
                          key={integration.id}
                          value={integration.ioss_number}
                        >
                          <img
                            src={`/images/integrations/${integration.market_place}.svg`}
                            style={{
                              width: "50px",
                              height: "20px",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                            alt={`${integration.market_place} logo`}
                          />{" "}
                          {integration.ioss_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}


            </Box>
            <Box sx={{
              display: "flex", justifyContent: "flex-end", marginBottom: "30px"
            }}>
              {/* <Button onClick={handleBulkUpdate} className="iyzi-button-blue">
                    Toplu Güncelle
                  </Button> */}
            </Box>
            <Box className="package-inputs-single">
              <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between", marginBottom: "20px" }}>
                <FormControl sx={{ width: "20%" }}>
                  <InputLabel id="select-country">Menşe Ülke</InputLabel>
                  <Select
                    labelId="select-country"
                    id="select-simple-country"
                    value={productCountryChanges || ""}
                    onChange={(e) => setProductCountryChanges(e.target.value)}
                    label="Menşe Ülke"
                  >
                    <MenuItem value="">
                      <em>Temizle</em>
                    </MenuItem>
                    {countries.map((country, key) => (
                      <MenuItem key={key} value={country.code}>
                        <img
                          loading="lazy"
                          style={{ marginRight: "5px", width: "20px" }}
                          src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                          alt={`${country.label} Milli Bayrağı Görseli`}
                        />
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  value={productTitle || ""}
                  onChange={(e) => setProductTitle(e.target.value)}
                  label="Ürün Açıklaması"
                  name="title"
                  sx={{ width: "30%" }}
                />
                <TextField
                  value={productSku || ""}
                  onChange={(e) => setProductSku(e.target.value)}
                  label="SKU"
                  name="sku"
                  sx={{ width: "20%" }}
                />
                <TextField
                  value={productHscode || ""}
                  onChange={(e) => setProductHscode(e.target.value)}
                  label="HS Kodu"
                  name="hs_code"
                  autoComplete="off"
                  inputProps={{
                    minLength: 6,
                    maxLength: 12,
                  }}
                  onBlur={(e) => handleHSCodeBlur(e)}
                  error={hsCodeError}
                  helperText={
                    <>
                      {hsCodeError && "Hs Kodu 6 haneden küçük girilemez."}
                      {!hsCodeError && (
                        <a
                          rel="noreferrer"
                          href="https://uygulama.gtb.gov.tr/Tara/"
                          target="_blank"
                          tabIndex={-1}
                          style={{
                            fontSize: "inherit",
                            color: "var(--btnBgBlue)",
                          }}
                        >
                          GTIP - HSCODE sorgula
                        </a>
                      )}
                    </>
                  }
                  sx={{ width: "15%" }}
                />
                <TextField
                  value={productQuantity || ""}
                  type="number"
                  onChange={(e) => setProductQuantity(e.target.value)}
                  label="Miktar"
                  name="qty"
                  inputProps={{ min: 1 }}
                  error={productQuantity < 1 && productQuantity.length !== 0}
                  helperText={
                    productQuantity < 1 &&
                    productQuantity.length !== 0 &&
                    "Miktar 1'den küçük olamaz."
                  }
                  sx={{ width: "10%" }}
                />
                <TextField
                  value={productPrice || ""}
                  onChange={(e) => setProductPrice(e.target.value)}
                  label="Birim Fiyatı"
                  name="price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>{Currency(currencyChanges)}</InputAdornment>
                    ),
                  }}
                  sx={{ width: "15%" }}
                />
                {/* <Button
                  onClick={() => handleRemoveProformaRow}
                  sx={{ minWidth: "40px", height: "40px", visibility: "hidden" }}
                >
                  <DeleteIcon />
                </Button> */}
              </Box>
              {proformaRows.map((row, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  <FormControl sx={{ width: "20%" }}>
                    <InputLabel id={`select-country-${index}`}>Menşe Ülke</InputLabel>
                    <Select
                      labelId={`select-country-${index}`}
                      id={`select-simple-country-${index}`}
                      value={row.country}
                      onChange={(e) =>
                        setProformaRows((prevRows) =>
                          prevRows.map((item, i) =>
                            i === index
                              ? { ...item, country: e.target.value }
                              : item
                          )
                        )
                      }
                      label="Menşe Ülke"
                    >
                      <MenuItem value="">
                        <em>Temizle</em>
                      </MenuItem>
                      {countries.map((country, key) => (
                        <MenuItem key={key} value={country.code}>
                          <img
                            loading="lazy"
                            style={{ marginRight: "5px", width: "20px" }}
                            src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                            alt={`${country.label} Milli Bayrağı Görseli`}
                          />
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    value={row.title}
                    onChange={(e) =>
                      setProformaRows((prevRows) =>
                        prevRows.map((item, i) =>
                          i === index ? { ...item, title: e.target.value } : item
                        )
                      )
                    }
                    label="Ürün Açıklaması"
                    name="title"
                    sx={{ width: "30%" }}
                    InputProps={{
                      endAdornment: memberId === 100003879 ? (
                        <InputAdornment position="end">
                          {row.title.length} / 128
                        </InputAdornment>
                      ) : null,
                    }}
                    helperText={
                      memberId === 100003879
                        ? "Easypost gönderilerinizde ürün açıklaması 128 karakteri geçmemelidir."
                        : ""
                    }
                  />
                  <TextField
                    value={row.sku}
                    onChange={(e) =>
                      setProformaRows((prevRows) =>
                        prevRows.map((item, i) =>
                          i === index ? { ...item, sku: e.target.value } : item
                        )
                      )
                    }
                    label="SKU"
                    name="sku"
                    sx={{ width: "20%" }}
                  />
                  <TextField
                    value={row.hs_code}
                    onChange={(e) =>
                      setProformaRows((prevRows) =>
                        prevRows.map((item, i) =>
                          i === index ? { ...item, hs_code: e.target.value } : item
                        )
                      )
                    }
                    label="HS Kodu"
                    name="hs_code"
                    autoComplete="off"
                    inputProps={{
                      minLength: 6,
                      maxLength: 12,
                    }}
                    onBlur={(e) => handleHSCodeBlurRows(e, index)} // index parametresini buradan gönderiyoruz
                    error={hsCodeError}
                    sx={{ width: "15%" }}
                  />
                  <TextField
                    value={row.qty}
                    type="number"
                    onChange={(e) =>
                      setProformaRows((prevRows) =>
                        prevRows.map((item, i) =>
                          i === index ? { ...item, qty: e.target.value } : item
                        )
                      )
                    }
                    label="Miktar"
                    name="qty"
                    inputProps={{ min: 1 }}
                    error={row.qty < 1 && row.qty.length !== 0}
                    helperText={
                      row.qty < 1 &&
                      row.qty.length !== 0 &&
                      "Miktar 1'den küçük olamaz."
                    }
                    sx={{ width: "10%" }}
                  />
                  <TextField
                    value={row.price}
                    onChange={(e) =>
                      setProformaRows((prevRows) =>
                        prevRows.map((item, i) =>
                          i === index ? { ...item, price: e.target.value } : item
                        )
                      )
                    }
                    label="Birim Fiyatı"
                    name="price"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>{Currency(currencyChanges)}</InputAdornment>
                      ),
                    }}
                    sx={{ width: "15%" }}
                  />
                  {/* <Button
                    onClick={() => handleRemoveProformaRow(index)}
                    sx={{ minWidth: "40px", height: "40px" }}
                  >
                    <DeleteIcon />
                  </Button> */}
                </Box>
              ))}
            </Box>

          </Box>
          {/* <Button
            onClick={handleAddNewProformaRow}
            className="iyzi-button-blue"
            sx={{
              marginLeft: "10px",
              minWidth: "40px",
              height: "40px",
            }}
          >
            <AddCircleIcon />
          </Button> */}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--background)",
                boxShadow: "var(--shadow)",
                padding: "10px",
                borderRadius: "var(--inputBorderRadius)",
                color: "var(--priceText)",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Toplam Fiyat:
              {Currency(currencyChanges)}
              {LocalPrice(productPrice)}{" "}
            </div>
          </div> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button
              type="button"
              onClick={handleCloseProforma}
              className="iyzi-button-blue"
            >
              İptal
            </Button>
            <Tooltip
              title={message()}
            >
              <span>
                <Button

                  className="iyzi-button-blue"
                  onClick={handleEditSubmit}
                >
                  Kaydet
                </Button>
              </span>
            </Tooltip>
          </Box>
          {Number(parcelTypeChanges) === 3 && (
            <div
              style={{
                boxShadow: 'var(--shadow)',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--gap2x)',
                borderRadius: 'var(--inputBorderRadius)',
              }}
            >
              <div
                style={{
                  backgroundColor: 'var(--pastelBlue)',
                  padding: '10px 20px',
                  borderRadius: 'var(--wrapperBorderRadius)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: 'var(--btnBgBlue)',
                }}
              >
                <InfoOutlinedIcon sx={{ color: 'inherit', fontWeight: 600 }} />
                <div style={{ color: 'inherit' }}>
                  <span style={{ fontWeight: 600 }}>
                    Talep edilen evraklar;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span style={{ fontWeight: 300, color: "red" }}>Türkçe E-arşiv fatura (ZORUNLU)</span>
                </div>
              </div>
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <Button className="dropzone-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{' '}
                        <span style={{ color: 'var(--btnBgBlue)' }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar: PDF
                      </span>
                    </div>
                  </Button>
                </Box>
                <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
              </Box>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={openFastDimensionNew}
        onClose={handleCloseNew}
        className="fast-dimension-modal"
      >
        <form
          className="fast-dimension"
          onSubmit={handleAddNewDimension}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleCloseNew}
            />
          </Box>
          <Box className="fast-dimension-input">
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px",
              }}
              size="small"
              value={enNew}
              onChange={handleDecimalChangeNew}
              label="En (cm)"
              name="en"
              required
              autoComplete="off"
            />
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px",
              }}
              size="small"
              value={boyNew}
              onChange={handleDecimalChangeNew}
              label="Boy (cm)"
              name="boy"
              required
              autoComplete="off"
            />
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px",
              }}
              size="small"
              value={yukNew}
              onChange={handleDecimalChangeNew}
              label="Yükseklik (cm)"
              name="yuk"
              required
              autoComplete="off"
            />
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px",
              }}
              size="small"
              value={kgNew}
              onChange={handleDecimalChangeNew}
              label="Kg"
              name="gross"
              required
              autoComplete="off"
            />
            <TextField
              disabled
              sx={{
                width: "170px",
                borderRadius: "5px",
              }}
              size="small"
              value={"1"}
              onChange={handleDecimalChangeNew}
              label="Adet"
              name="qty"
              required
              autoCompete="off"
            />
          </Box>
          <div className="form-dimensions-weight">
            <div>
              <span style={{ fontSize: "13px" }}>
                <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
              </span>
              <span style={{ fontSize: "13px" }}>
                {isNaN(Number(kgNew * 1).toFixed(2))
                  ? "0.00 KG"
                  : `${Number(kgNew * 1).toFixed(2)} KG`}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "13px" }}>
                <b style={{ fontSize: "inherit" }}>Hacim / Desi :</b>&nbsp;
              </span>
              <span style={{ fontSize: "13px" }}>
                {`${(
                  ((Math.ceil(Number(enNew)) *
                    Math.ceil(Number(boyNew)) *
                    Math.ceil(Number(yukNew))) /
                    5000) *
                  qtyNew
                ).toFixed(2)} KG`}
              </span>
            </div>
          </div>
          <Box className="fast-dimension-buttons">
            <Button
              type="button"
              onClick={handleCloseNew}
              className="iyzi-button-blue"
            >
              İptal
            </Button>
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>
          </Box>
        </form>
      </Modal>
      <Modal
        open={openFastDimension}
        onClose={handleClose}
        className="fast-dimension-modal"
      >
        <form
          className="fast-dimension"
          onSubmit={handleAddNewDimensionManuel}
          style={{
            maxHeight: "90vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
          </Box>
          {dimensions.map((dimension, index) => (
            <React.Fragment key={index}>
              <Box className="fast-dimension-input">
                <FormControl
                  sx={{ width: "140px" }}
                  size="small"
                  className="list-item-select"
                >
                  <InputLabel id={`dimension-select-label-${index}`}>
                    Hızlı Ölçü Seç
                  </InputLabel>
                  <Select
                    labelId={`dimension-select-label-${index}`}
                    id={`dimension-select-${index}`}
                    label="Hızlı Ölçü Seç"
                    value={`${dimension.en || dimension.width || en}x${dimension.boy || dimension.length || boy
                      }x${dimension.yuk || dimension.height || yuk} cm / ${dimension.gross || dimension.gross || kg
                      } kg`}
                    onChange={handleChangeDimension(index)}
                    sx={{ borderRadius: "5px" }}
                    IconComponent={null} // Aşağı doğru ikonu kaldırmak için
                  >
                    <MenuItem value="">Temizle</MenuItem>
                    {fastDimension.map((dim, key) => (
                      <MenuItem key={key} value={dim.id}>
                        {`${dim.width}x${dim.length}x${dim.height} cm / ${dim.gross} kg`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.en || dimension.width || en}
                  onChange={handleDecimalChange(index, "en")}
                  label="En (cm)"
                  name="en"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.boy || dimension.length || boy}
                  onChange={handleDecimalChange(index, "boy")}
                  label="Boy (cm)"
                  name="boy"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.yuk || dimension.height || yuk}
                  onChange={handleDecimalChange(index, "yuk")}
                  label="Yükseklik (cm)"
                  name="yuk"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.gross || dimension.gross || kg}
                  onChange={handleDecimalChange(index, "gross")}
                  label="Kg"
                  name="gross"
                  required
                  autoComplete="off"
                />
                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.qty}
                  onChange={handleDecimalChange(index, "qty")}
                  label="Adet"
                  name="qty"
                  required
                  autoCompete="off"
                />
                <Box>
                  <Button
                    className="delete-dimension"
                    style={{
                      minWidth: "5px",
                      visibility: index !== 0 ? "" : "hidden",
                    }}
                    onClick={() => handleRemoveInput(index)}
                  >
                    <DeleteIcon sx={{ height: "20px" }} />
                  </Button>
                </Box>
              </Box>
              <Box className="form-dimensions-weight">
                <div>
                  <span style={{ fontSize: "13px" }}>
                    <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {(dimension.gross * dimension.qty).toFixed(2)} KG
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "13px" }}>
                    <b style={{ fontSize: "inherit" }}>Hacim / Desi :</b>&nbsp;
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {Number(
                      ((Math.ceil(Number(dimension.width || dimension.en)) *
                        Math.ceil(Number(dimension.length || dimension.boy)) *
                        Math.ceil(Number(dimension.height || dimension.yuk))) /
                        5000) *
                      1
                    ).toFixed(2)}{" "}
                    KG
                  </span>
                </div>
              </Box>
            </React.Fragment>
          ))}
          <Box
            sx={{
              padding: "10px",
              minWidth: "250px",
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
            }}
          >
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "700",
                color: "var(--btnBgBlue)",
              }}
            >
              Toplam Ücret Ağırlığı
            </span>
            :{" "}
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "700",
                color: "var(--priceText)",
              }}
            >
              {iyziWeightRoundSystem(totalCw)} KG
            </span>
          </Box>
          <Button
            sx={{
              maxWidth: "1100px",
            }}
            onClick={handleAddInput}
            className="add-dimension-button"
          >
            <AddCircleOutlineIcon className="add-dimension-button-icon" />
          </Button>
          <Box className="fast-dimension-buttons">
            <Button
              onClick={() => setOpenFastDimensionNew(true)}
              className="iyzi-button-blue"
            >
              Hızlı Ölçü Oluştur <AddCircleIcon />
            </Button>
            <Button
              type="button"
              onClick={handleClose}
              className="iyzi-button-blue"
            >
              İptal
            </Button>
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>
          </Box>
        </form>
      </Modal>
      <Modal
        open={openFastSupplier}
        onClose={handleCloseFastSupplier}
        className="fast-dimension-modal"
      >
        <form
          className="fast-dimension"
          onSubmit={handleAddSupplier}
          style={{
            maxHeight: "90vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleCloseFastSupplier}
            />
          </Box>
          <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
            {iossCountries?.find((e) => e.iso_code === fastChecked[0]?.receiver_country)
              ? suppliers
                ?.filter((sup) => [347, 309, 343, 342, 341, 302, 701, 337, 338].includes(sup.id))
                .map((sup) => (
                  <div
                    key={sup.id}
                    style={{
                      display: isButtonDisabled(sup.id) ? "none" : "",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "16px",
                      width: "200px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`/images/${sup.id}.svg`}
                      alt={sup.supplier_name}
                      style={{
                        width: "80px",
                        height: "80px",
                        marginBottom: "8px",
                        objectFit: "contain",
                      }}
                    />
                    <h3 style={{ fontSize: "16px", margin: "8px 0" }}>
                      {sup.id === 302 ? "HERMES" :
                        sup.id === 337 ? "UPS ECO" :
                          sup.id === 338 ? "DHL ECO EU" :
                            sup.id === 341 ? "DHL" :
                              sup.id === 342 ? "UPS SAVER" :
                                sup.id === 343 ? "Fedex" :
                                  sup.id === 345 ? "DHL ECO US" :
                                    sup.id === 347 ? "UPS DIRECT" :
                                      sup.id === 901 ? "UPS AMAZON" :
                                        sup.supplier_name}
                    </h3>
                    <p style={{ margin: "8px 0" }}>
                      Supplier ID: <strong>{sup.id}</strong>
                    </p>
                    <button
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelect(sup.id)}
                    >
                      Fiyat Seç
                    </button>
                  </div>
                ))
              : suppliers
                ?.filter((sup) => [302, 342, 341, 343, 901, 347, 345, 306].includes(sup.id))
                .map((sup) => (
                  <div
                    key={sup.id}
                    style={{
                      display: isButtonDisabled(sup.id) ? "none" : "",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "16px",
                      width: "200px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`/images/${sup.id}.svg`}
                      alt={sup.supplier_name}
                      style={{
                        width: "80px",
                        height: "80px",
                        marginBottom: "8px",
                        objectFit: "contain",
                      }}
                    />
                    <h3 style={{ fontSize: "16px", margin: "8px 0" }}>
                      {sup.id === 302 ? "HERMES" :
                        sup.id === 337 ? "UPS ECO" :
                          sup.id === 338 ? "DHL ECO EU" :
                            sup.id === 341 ? "DHL" :
                              sup.id === 342 ? "UPS SAVER" :
                                sup.id === 343 ? "Fedex" :
                                  sup.id === 345 ? "DHL ECO US" :
                                    sup.id === 347 ? "UPS DIRECT" :
                                      sup.id === 901 ? "UPS AMAZON" :
                                        sup.supplier_name}
                    </h3>
                    <p style={{ margin: "8px 0" }}>
                      Supplier ID: <strong>{sup.id}</strong>
                    </p>
                    <button
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelect(sup.id)}
                    >
                      Fiyat Seç
                    </button>
                  </div>
                ))
            }
          </div>
        </form>
      </Modal>
      <Dialog
        open={openFastSupplierCountryError}
        onClose={handleCloseFastSupplierCountryError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Uyarı"}</DialogTitle>
        <DialogContent>
          <DialogContentText

            sx={{ fontWeight: 500, fontSize: "16px" }}
            id="alert-dialog-description"
          >
            Toplu Tedarikçi Ata işlemine devam edebilmek için seçilen gönderilerin
            alıcı ülkeleri ve proforma kısmındaki gönderi açıklaması bilgilerinin aynı olması gerekmektedir.

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleCloseFastSupplierCountryError}>
            Anladım
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={supplierLoading}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "var(--gap)",
            justifyContent: "center",
            minHeight: "300px", // Minimum yükseklik
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <img className="loading-logo" src={miniLogo} alt="Logo" width={100} />
          <Typography variant="h6" align="center">
            Fiyatlar Alınıyor, lütfen bekleyiniz...
          </Typography>
        </DialogContent>
      </Dialog>
      <Modal
        open={openFastDimensionUpdate}
        onClose={handleClose}
        className="fast-dimension-modal"
      >
        <form
          className="fast-dimension"
          onSubmit={handleAddNewDimensionUpdate}
          style={{
            maxHeight: "90vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
          </Box>
          {dimensions.map((dimension, index) => (
            <React.Fragment key={index}>
              <Box className="fast-dimension-input">
                <FormControl
                  sx={{ width: "140px" }}
                  size="small"
                  className="list-item-select"
                >
                  <InputLabel id={`dimension-select-label-${index}`}>
                    Hızlı Ölçü Seç
                  </InputLabel>
                  <Select
                    labelId={`dimension-select-label-${index}`}
                    id={`dimension-select-${index}`}
                    label="Hızlı Ölçü Seç"
                    value={`${dimension.en || dimension.width || en}x${dimension.boy || dimension.length || boy
                      }x${dimension.yuk || dimension.height || yuk} cm / ${dimension.gross || dimension.gross || kg
                      } kg`}
                    onChange={handleChangeDimension(index)}
                    sx={{ borderRadius: "5px" }}
                    IconComponent={null} // Aşağı doğru ikonu kaldırmak için
                  >
                    <MenuItem value="">Temizle</MenuItem>
                    {fastDimension.map((dim, key) => (
                      <MenuItem key={key} value={dim.id}>
                        {`${dim.width}x${dim.length}x${dim.height} cm / ${dim.gross} kg`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.en || dimension.width || en}
                  onChange={handleDecimalChange(index, "en")}
                  label="En (cm)"
                  name="en"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.boy || dimension.length || boy}
                  onChange={handleDecimalChange(index, "boy")}
                  label="Boy (cm)"
                  name="boy"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.yuk || dimension.height || yuk}
                  onChange={handleDecimalChange(index, "yuk")}
                  label="Yükseklik (cm)"
                  name="yuk"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.gross || dimension.gross || kg}
                  onChange={handleDecimalChange(index, "gross")}
                  label="Kg"
                  name="gross"
                  required
                  autoComplete="off"
                />
                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.qty}
                  onChange={handleDecimalChange(index, "qty")}
                  label="Adet"
                  name="qty"
                  required
                  autoCompete="off"
                />
                <Box>
                  <Button
                    className="delete-dimension"
                    style={{
                      minWidth: "5px",
                      visibility: index !== 0 ? "" : "hidden",
                    }}
                    onClick={() => handleRemoveInput(index)}
                  >
                    <DeleteIcon sx={{ height: "20px" }} />
                  </Button>
                </Box>
              </Box>
              <Box className="form-dimensions-weight">
                <div>
                  <span style={{ fontSize: "13px" }}>
                    <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {(dimension.gross * dimension.qty).toFixed(2)} KG
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "13px" }}>
                    <b style={{ fontSize: "inherit" }}>Hacim / Desi :</b>&nbsp;
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {Number(
                      ((Math.ceil(Number(dimension.width || dimension.en)) *
                        Math.ceil(Number(dimension.length || dimension.boy)) *
                        Math.ceil(Number(dimension.height || dimension.yuk))) /
                        5000) *
                      1
                    ).toFixed(2)}{" "}
                    KG
                  </span>
                </div>
              </Box>
            </React.Fragment>
          ))}
          <Box
            sx={{
              padding: "10px",
              minWidth: "250px",
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
            }}
          >
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "700",
                color: "var(--btnBgBlue)",
              }}
            >
              Toplam Ücret Ağırlığı
            </span>
            :{" "}
            <span
              style={{
                fontSize: "inherit",
                fontWeight: "700",
                color: "var(--priceText)",
              }}
            >
              {iyziWeightRoundSystem(totalCw)} KG
            </span>
          </Box>
          <Button
            sx={{
              maxWidth: "1100px",
            }}
            onClick={handleAddInput}
            className="add-dimension-button"
          >
            <AddCircleOutlineIcon className="add-dimension-button-icon" />
          </Button>
          <Box className="fast-dimension-buttons">
            <Button
              onClick={() => setOpenFastDimensionNew(true)}
              className="iyzi-button-blue"
            >
              Hızlı Ölçü Oluştur <AddCircleIcon />
            </Button>
            <Button
              type="button"
              onClick={handleClose}
              className="iyzi-button-blue"
            >
              İptal
            </Button>
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>
          </Box>
        </form>
      </Modal>
      <Modal
        open={errorModalOpen}
        onClose={handleErrorModal}
        className="fast-dimension-modal"
      >
        <Box
          sx={{
            backgroundColor: "none",
          }}
          className="fast-dimension-modal"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "white",
              padding: "30px", // Optional: Add some padding for better appearance
              borderRadius: "8px", // Optional: Add rounded corners for a nicer look
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for a floating effect
            }}
          >
            <p>
              Seçilen gönderilerin bazılarında sorun meydana geldi.{" "}
              {errorModalMessage}
            </p>
            <p>İlgili gönderi sipariş numaraları:</p>
            <div>
              {errorModalList.map((item, index) => (
                <span key={index}>{item.receipt_id}</span>
              ))}
            </div>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Button className="iyzi-button-blue" onClick={handleErrorModal}>
                Anladım
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={uploadFileModal}
        onClose={handUploadFileModal}
        className="fast-dimension-modal"
      >
        <Box
          sx={{
            backgroundColor: "none",
          }}
          className="fast-dimension-modal"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "white",
              padding: "30px", // Optional: Add some padding for better appearance
              borderRadius: "8px", // Optional: Add rounded corners for a nicer look
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for a floating effect
            }}
          >
            <div
              style={{
                boxShadow: "var(--shadow)",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "var(--gap2x)",
                borderRadius: "var(--inputBorderRadius)",
              }}
            >
              <div
                style={{
                  backgroundColor: "var(--pastelBlue)",
                  padding: "10px 20px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "var(--btnBgBlue)",
                }}
              >
                <InfoOutlinedIcon sx={{ color: "inherit", fontWeight: 600 }} />
                <div style={{ color: "inherit" }}>
                  <span style={{ fontWeight: 300 }}>CSV uzantılı dosya</span>
                </div>
              </div>
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <Button className="dropzone-area" {...getRootPropsCSV()}>
                    <input {...getInputPropsCSV()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{" "}
                        <span style={{ color: "var(--btnBgBlue)" }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar: CSV
                      </span>
                    </div>
                  </Button>
                </Box>
                <DowloadPdfView
                  sendFiles={sendFiles}
                  handleSetState={handleSetState}
                />
                {selectedFiles && (
                  <>
                    <Box>Seçilen Dosya</Box>
                    <Box>{selectedFiles?.file?.path}</Box>
                  </>
                )}
              </Box>
            </div>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              {loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  className="iyzi-button-blue"
                  onClick={handleUploadModal}
                >
                  Gönder
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={uploadFileExcelModal}
        onClose={handleUploadFileExcelModalClose}
        className="fast-dimension-modal"
      >
        <Box
          sx={{
            backgroundColor: "none",
          }}
          className="fast-dimension-modal"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "white",
              padding: "30px", // Optional: Add some padding for better appearance
              borderRadius: "8px", // Optional: Add rounded corners for a nicer look
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for a floating effect
            }}
          >
            <div
              style={{
                boxShadow: "var(--shadow)",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "var(--gap2x)",
                borderRadius: "var(--inputBorderRadius)",
              }}
            >
              <div
                style={{
                  backgroundColor: "var(--pastelBlue)",
                  padding: "10px 20px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "var(--btnBgBlue)",
                }}
              >
                <InfoOutlinedIcon sx={{ color: "inherit", fontWeight: 600 }} />
                <div style={{ color: "inherit" }}>
                  <span style={{ fontWeight: 300 }}>Excel uzantılı dosya</span>
                </div>
              </div>
              <Box className="dropzone-wrapper">
                <Box className="dropzone">
                  <Button className="dropzone-area" {...getRootPropsExcel()}>
                    <input {...getInputPropsExcel()} />
                    <div className="dropzone-area-text">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span className="dropzone-area-text-headline">
                        Dosyayı sürükleyip bırakın veya{" "}
                        <span style={{ color: "var(--btnBgBlue)" }}>Gözat</span>
                      </span>
                      <span className="dropzone-area-text-sub">
                        Desteklenen Formatlar: xlsx
                      </span>
                    </div>
                  </Button>
                </Box>
                <DowloadPdfView
                  sendFiles={sendFiles}
                  handleSetState={handleSetState}
                />
                {selectedFiles && (
                  <>
                    <Box>Seçilen Dosya</Box>
                    <Box>{selectedFiles?.file?.path}</Box>
                  </>
                )}
              </Box>
            </div>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              {loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  className="iyzi-button-blue"
                  onClick={handleUploadModalExcel}
                >
                  Gönder
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <AllSendDialog
        open={allSendDialog}
        setOpen={setAllSendDialog}
        handleSubmit={allFastShipmentSend}
      />
      <FastDimension />
      {!isDimensionExist && (
        <div
          style={{
            backgroundColor: "var(--lighterBg)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            boxShadow: "var(--shadow)",
            borderRadius: "var(--wrapperBorderRadius)",
            gap: "var(--gap)",
            fontSize: "16px",
          }}
        >
          <InfoOutlinedIcon
            sx={{ color: "var(--info)", width: "25px", height: "25px" }}
          />
          <p
            style={{
              fontSize: "16px",
            }}
          >
            Fiyat almak için{" "}
            <NavLink
              style={{ fontSize: "inherit" }}
              to={"/profile-settings/registered-dimensions"}
            >
              buradan
            </NavLink>{" "}
            kayıtlı ölçü oluşturun.
          </p>
        </div>
      )}
      {window.location.href.endsWith("create-fast-shipment") && (
        <div className="fast-shipment-list-buttons">
          <Button
            sx={{
              backgroundColor: "#EB6D20 !important",
              borderColor: "#EB6D20 !important",
            }}
            className="iyzi-button-blue"
            onClick={handleEtsyIntegration}
          >
            Etsy Siparişlerimi Güncelle
          </Button>
          <Button
            sx={{
              backgroundColor: "#4A9C14 !important",
              borderColor: "#4A9C14 !important",
            }}
            className="iyzi-button-blue"
            onClick={handleShopifyIntegration}
          >
            Shopify Siparişlerimi Güncelle
          </Button>
          <Button
            sx={{
              backgroundColor: "#7F54B3 !important",
              borderColor: "#7F54B3 !important",
            }}
            className="iyzi-button-blue"
            onClick={handleWooCommerceIntegration}
          >
            WooCommerce Siparişlerimi Güncelle
          </Button>
          {/* <Button
            sx={{
              backgroundColor: "#0064D2 !important",
              borderColor: "#0064D2 !important",
            }}
            className="iyzi-button-blue"
            onClick={handleEbayIntegration}
          >
            Ebay Siparişlerimi Güncelle
          </Button> */}
        </div>
      )}
      {fastList?.length === 0 &&
        dataRendered &&
        window.location.href.endsWith("create-fast-shipment") && (
          <div
            style={{
              backgroundColor: "var(--lighterBg)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              boxShadow: "var(--shadow)",
              borderRadius: "var(--wrapperBorderRadius)",
              gap: "var(--gap)",
              fontSize: "20px",
            }}
          >
            <InfoOutlinedIcon
              sx={{ color: "var(--info)", width: "25px", height: "25px" }}
            />{" "}
            <span style={{ fontSize: "16px" }}>
              Entegre gönderiniz bulunmamaktadır. Mağazanızın bağlı olup
              olmadığını{" "}
              <NavLink
                style={{ color: "var(--btnBgBlue)", fontSize: "inherit" }}
                to={"/integrations"}
              >
                buradan
              </NavLink>{" "}
              kontrol edebilirsiniz.
            </span>
          </div>
        )}
      <FastShipmentSearch setLoading={setLoading} />
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "var(--gap)",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <img className="loading-logo" src={miniLogo} alt="" />
          <div>Tüm gönderileri çekmek biraz zamanımızı alabilir...</div>
        </div>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                gap: "10px",
              },
            }}
            className="fast-shipment-pagination"
          >
            <Pagination
              showFirstButton
              showLastButton
              size="small"
              count={fastShipmentTotalPages}
              page={fastShipmentCurrentPage}
              onChange={handlePageChangeManuel}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Box sx={{
                  display: "flex",
                  width: "100%",
                  gap: "20px",
                }}>
                  {window.location.href.endsWith("create-fast-shipment") && (
                    <Button
                      sx={{
                        visibility: "hidden",
                        width: "100%",
                        ":disabled": { opacity: 0.3 },
                        "@media (max-width: 768px)": {
                          width: "84%",
                        },
                      }}
                      // className="iyzi-button-blue"
                      onClick={handleUploadFileModal}
                    >
                      {/* İçeri Aktar */}
                    </Button>
                  )}
                  {window.location.href.endsWith("create-fast-shipment") && (
                    <Button
                      sx={{
                        width: "95%",
                        ":disabled": { opacity: 0.3 },
                        "@media (max-width: 768px)": {
                          width: "84%",
                        },
                      }}
                      className="iyzi-button-blue"
                      onClick={handleUploadFileModal}
                    >
                      İçeri Aktar
                    </Button>
                  )}

                  {window.location.href.endsWith("create-fast-all-shipment") && (
                    <>
                      <Button
                        sx={{
                          width: "100%",
                          ":disabled": { opacity: 0.3 },
                          "@media (max-width: 768px)": {
                            width: "84%",
                          },
                        }}
                        className="iyzi-button-blue"
                        onClick={handleUploadFileExcelModal}
                      >
                        Excel Yükle
                      </Button>
                      <Button
                        sx={{
                          width: "100%",
                          ":disabled": { opacity: 0.3 },
                          "@media (max-width: 768px)": {
                            width: "84%",
                          },
                        }}
                        className="iyzi-button-blue"
                        onClick={handleDownloadExcelCopy}
                      >
                        Örnek Excel İndir
                      </Button>
                    </>
                  )}
                  {bulkLoading ? (
                    <Dialog
                      open={bulkLoading}
                      fullWidth
                      maxWidth="sm"
                      PaperProps={{
                        style: {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "var(--gap)",
                          justifyContent: "center",
                          minHeight: "300px", // Minimum yükseklik
                        },
                      }}
                    >
                      <DialogContent
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <img className="loading-logo" src={miniLogo} alt="Logo" width={100} />
                        <Typography variant="h6" align="center">
                          Gönderiler Oluşturuluyor, lütfen bekleyiniz...
                        </Typography>
                      </DialogContent>
                    </Dialog>
                  ) : (
                    <Tooltip
                      title={
                        fastChecked.length < 2 ||
                          fastChecked.map((item) => item.isPriceTake) === 0
                          ? "Toplu gönderi oluşturabilmeniz için ürün seçmeniz ve ürünlerin fiyatlarının alınmış olması gerekmektedir."
                          : ""
                      }
                    >
                      <div style={{ width: window.location.href.endsWith("create-fast-all-shipment") ? "50%" : "100%" }}>
                        <Button
                          sx={{
                            width: "100%",
                            ":disabled": { opacity: 0.3 },
                            "@media (max-width: 768px)": {
                              width: "84%",
                            },
                          }}
                          disabled={
                            fastChecked.length < 2 ||
                            fastList.map((item) => item.isPriceTake) === 0
                            // !fastList.some((item) => item.prices?.contract_code)
                          }
                          className="iyzi-button-blue"
                          onClick={allFastShipmentDialog}
                        >
                          Toplu Gönderi Oluştur
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </Box>
                <Box sx={{
                  display: "flex",
                  width: "100%",
                  gap: "20px",
                }}>
                  <Button
                    sx={{
                      width: "100%",
                      ":disabled": { opacity: 0.3 },
                      "@media (max-width: 768px)": {
                        width: "84%",
                      },
                    }}
                    disabled={fastChecked.length < 2}
                    className="iyzi-button-blue"
                    onClick={handleDimensionModal}
                  >
                    Toplu Ölçü Atama
                  </Button>
                  <Tooltip
                    title={
                      fastChecked.length < 2
                        ? "Toplu Proforma Atamanız için 2 veya daha fazla gönderi seçmeniz gerekmektedir."
                        : ""
                    }
                  >

                    <Button
                      sx={{
                        width: "100%",
                        ":disabled": { opacity: 0.3 },
                        "@media (max-width: 768px)": {
                          width: "84%",
                        },
                        position: "relative",
                      }}
                      disabled={
                        fastChecked.length < 2
                        // || fastChecked.some(
                        //   (item, _, array) =>
                        //     item.receiver_address.country !== array[0].receiver_address.country
                        // )
                      }
                      className="iyzi-button-blue"
                      onClick={handleProformaModal}
                    >
                      Toplu Proforma Atama
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      fastChecked.length < 2 || !fastChecked.every(item => item.receiver_country === fastChecked[0].receiver_country)
                        ? "Toplu Tedarikçi Atamanız için 2 veya daha fazla gönderi seçmeniz ve seçilen gönderilerin alıcı adreslerinin aynı olması gerekmektedir."
                        : ""
                    }
                  >
                    <div style={{ width: "100%" }}>
                      <Button
                        sx={{
                          width: "100%",
                          ":disabled": { opacity: 0.3 },
                          "@media (max-width: 768px)": {
                            width: "84%",
                          },
                        }}
                        disabled={
                          fastChecked.length < 2
                        }
                        className="iyzi-button-blue"
                        onClick={handleSupplierModal}
                      >
                        Toplu Tedarikçi Atama
                      </Button>
                    </div>
                  </Tooltip>

                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    className="iyzi-button-greenBold"
                    sx={{
                      width: "15px",
                      height: "15px",
                    }}
                  ></Box>
                  <p>Tamamlandı</p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    className="iyzi-button-yellow"
                    sx={{
                      width: "15px",
                      height: "15px",
                    }}
                  ></Box>
                  <p>İsteğe Bağlı Değiştirilebilir</p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    className="iyzi-button-red"
                    sx={{
                      width: "15px",
                      height: "15px",
                    }}
                  ></Box>
                  <p>Zorunlu</p>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                gap: "10px",
              },
            }}
            className="fast-shipment-pagination"
          >
            <b>- Sipariş güncellemelerinde ve gönderi oluşturma işlemlerinde sorun yaşıyor iseniz destek hattı ile iletişime geçiniz.</b>
          </Box>
          <FastShipmentListHead
            profileSenderAddress={profileSenderAddress}
            allChecked={allChecked}
            setAllChecked={setAllChecked}
          />
          {fastList.length > 9 && (
            <>
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={fastShipmentTotalPages}
                page={fastShipmentCurrentPage}
                onChange={handlePageChangeManuel}
                className="fast-shipment-pagination"
              />
              {bulkLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{ width: "21px!important", height: "21px!important" }}
                  />
                </div>
              ) : (
                <Tooltip
                  className="fast-shipment-pagination"
                  title={
                    fastChecked.length < 2 ||
                      fastChecked.map((item) => item.isPriceTake) === 0
                      ? "Toplu gönderi oluşturabilmeniz için ürün seçmeniz gerekmektedir."
                      : ""
                  }
                >
                  <div style={{ width: "100%" }}>
                    <Button
                      sx={{
                        ":disabled": { opacity: 0.3 },
                        marginBottom: "10px",
                      }}
                      disabled={
                        fastChecked.length < 2 ||
                        fastChecked.map((item) => item.isPriceTake) === 0
                      }
                      className="iyzi-button-blue"
                      onClick={allFastShipmentDialog}
                    >
                      Toplu Gönderi Oluştur
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(FastShipmentList);
