import { Button, Modal } from "@mui/material";
import { useEffect } from "react";

const ChangePasswordInfo = ({ open, setOpen }) => {
  useEffect(() => {
    const isClosed = localStorage.getItem('ramadan-modal');
    if (isClosed === '1') {
      setOpen(false);
    }
  }, [setOpen]);

  const handleClose = () => {
    localStorage.setItem('ramadan-modal', '1');
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        zIndex: 9999,
        outline: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
          backgroundColor: "var(--lighterBg)",
          maxWidth: "690px",
          width: "100%",
          overflow: "auto",
          borderRadius: "var(--wrapperBorderRadius)",
          outline: "none",
          maxHeight: "90vh",
          padding: "20px"
        }}
      >
        {/* <figure
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--pastelBlue)",
          }}
        >
          <img
            src="/images/heros/heros.png"
            alt="iyziTeam"
            style={{ width: "100%" }}
          />
        </figure> */}
        <div
          style={{ padding: "10px", display: "flex", flexDirection: "column", gap: "var(--gap)" }}
        >
          <h2
            style={{
              fontSize: "22px",
              lineHeight: "24px",
              color: "var(--btnBgBlue)",
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            Değerli Üyelerimiz,
          </h2>
          {/* <h2
            style={{
              fontSize: "22px",
              lineHeight: "24px",
              color: "var(--btnBgBlue)",
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            SİZLER İÇİN PANELİMİZDE BİRKAÇ GÜNCELLEME YAPTIK :)
          </h2> */}
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            Ramazan Bayramı dolayısıyla 31 Mart Pazartesi ve 1 Nisan Salı günleri operasyonlarımıza kısa bir ara vereceğiz.
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            2 Nisan Çarşamba günü itibarıyla normal çalışma düzenimize kaldığımız yerden devam edeceğiz.
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            Bu vesileyle, sevdiklerinizle birlikte sağlık, huzur ve mutluluk dolu bir bayram geçirmenizi dileriz.
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            Ramazan Bayramınız mübarek olsun.
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            Saygılarımızla,
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            iyziTeam
          </p>
          <Button className="iyzi-button-blue" onClick={handleClose}>
            Tamam
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordInfo;
