import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Modal,
  InputAdornment
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  activeStepState,
  arrivalCountryCodeState,
  confirmationCodeState,
  ddpState,
  dimensionArrayState,
  insuranceState,
  iossNumberState,
  overPayOpenState,
  overPayState,
  priceIdState,
  productCurrencyState,
  productValueState,
  proformaModalState,
  selectedReceiverAddressState,
  selectedSenderAddressState,
  shipmentDescriptionState,
  shipmentDocumentState,
  supplierIdState,
  totalCwState,
  totalGwState,
  totalQtyState,
  totalVwState,
  senderCreateCountryState,
  contractIdsState,
  lucidState,
  arrivalCountryState,
  setOpenWarningModalState,
  isEuropeanCountryState,
  isAbove150EURCheckState
} from "../../atoms/createShipment";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import { useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../img/icons/upload_icon.svg";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import iossCountries from "../../json/iossCountries.json";
import Currency from "../../utils/CurrencySymbol";
import { integrationsState, newIossNumberAnimationState } from "../../atoms/membership";
import CreateProformaIOSSModal from "./shipmentProforma/CreateProformaIOSSModal";
import DowloadPdfView from "../dowloadPdfView/DowloadPdfView";
import ProformaOverPay from "./shipmentProforma/ProformaOverPay";
import LocalPrice from "../../utils/LocalPrice";
import Errors from "../../utils/Errors";
import { platformCountryState } from "../../atoms/profileSettings";
import ProformaWarning from "./shipmentProforma/ProformaWarning";
import CountriesTurkishSorted from "../../utils/CountriesTurkishSorted";
import ProformaHSCodeErrorDialog from "./shipmentProforma/ProformaHSCodeErrorDialog";
import { EngCharUpperCase } from "../../utils/UpperCase";
import ProformaIossError from "./shipmentProforma/ProformaIossError";

const theme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "4px 0 0",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: "flex",
          gap: "var(--gap)",
          alignItems: "center",
        },
      },
    },
  },
});

const ShipmentProforma = () => {
  const [token] = useCookies(["jwt"]);
  const jwt = token.jwt;
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;

  const dimensionArray = useRecoilValue(dimensionArrayState);
  const confirmationCode = useRecoilValue(confirmationCodeState);
  const contractIds = useRecoilValue(contractIdsState);
  const priceId = useRecoilValue(priceIdState);
  const shipmentDocument = useRecoilValue(shipmentDocumentState);
  const arrivalCountryCode = useRecoilValue(arrivalCountryCodeState);
  const totalCw = useRecoilValue(totalCwState);
  const totalGw = useRecoilValue(totalGwState);
  const totalVw = useRecoilValue(totalVwState);
  const selectedSenderAddress = useRecoilValue(selectedSenderAddressState);
  const selectedReceiverAddress = useRecoilValue(selectedReceiverAddressState);
  const insurance = useRecoilValue(insuranceState);
  const ddp = useRecoilValue(ddpState);
  const lucid = useRecoilValue(lucidState);
  const supplierId = useRecoilValue(supplierIdState);
  const productValue = useRecoilValue(productValueState);
  const productCurrency = useRecoilValue(productCurrencyState);
  const arrivalCountry = useRecoilValue(arrivalCountryState);
  const isEuropeanCountry = useRecoilValue(isEuropeanCountryState);
  const isAbove150EURCheck = useRecoilValue(isAbove150EURCheckState);

  const [iossNumber, setIossNumber] = useRecoilState(iossNumberState);
  const [shipmentDescription, setShipmentDescription] = useRecoilState(shipmentDescriptionState);
  const [totalQty, setTotalQty] = useRecoilState(totalQtyState);
  const setActiveStep = useSetRecoilState(activeStepState);

  const [integrations, setIntegrations] = useRecoilState(integrationsState);
  const setProformaModal = useSetRecoilState(proformaModalState);
  const setNewIossNumberAnimation = useSetRecoilState(newIossNumberAnimationState);
  const setPlatformCountry = useSetRecoilState(platformCountryState);

  const senderCreateCountry = useRecoilValue(senderCreateCountryState);
  const [currency, setCurrency] = useState(productCurrency);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const defaultCountry = CountriesTurkishSorted().find(
    (option) => option.country_name_tr === senderCreateCountry
  );
  const member = JSON.parse(localStorage.getItem("user"));
  const memberId = member?.user?.user_member?.id;
  const [commoduties, setCommoduties] = useState([
    {
      id: 0,
      title: "",
      sku: "",
      hs_code: "",
      country: defaultCountry.iso_code,
      qty: "",
      price: "",
      currency: currency,
      total_price: "",
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [etgbInvoiceDate, setEtgbInvoiceDate] = useState("");
  const [etgbInvoiceNo, setEtgbInvoiceNo] = useState("");
  const [hsCodeError, setHsCodeError] = useState(false);
  const [currencyChange, setCurrencyChange] = useState(false);
  const [productDescriptionError, setProductDescriptionError] = useState(false);
  const [notEvenPriceDialog, setNotEvenPriceDialog] = useState(false);
  const [titleFocus, setTitleFocus] = useState(false);
  const hsCodeRef = useRef(null);

  const isFilledHsCode = commoduties.some((com) => com.hs_code === "");
  const [hsCodeDialog, setHsCodeDialog] = useState(false);

  const setOverPayOpen = useSetRecoilState(overPayOpenState);
  const setOverPay = useSetRecoilState(overPayState);

  const addInputDisabled =
    commoduties[commoduties.length - 1].title === "" ||
    //commoduties[commoduties.length - 1].sku === "" ||
    // commoduties[commoduties.length - 1].hs_code === "" ||
    commoduties[commoduties.length - 1].country === "" ||
    commoduties[commoduties.length - 1].price === "" ||
    commoduties[commoduties.length - 1].qty === "";

  const handleAddInput = useCallback(() => {
    if (!addInputDisabled) {
      setCommoduties([
        ...commoduties,
        {
          id: commoduties.length,
          title: "",
          sku: "",
          hs_code: "",
          country: defaultCountry.iso_code,
          qty: "",
          price: "",
          currency: currency,
          total_price: "",
        },
      ]);
    }
  }, [commoduties, addInputDisabled, currency, defaultCountry, setCommoduties]);

  const handleHsCodeBlur = useCallback(
    (e, i) => {
      const values = [...commoduties];
      const inputLength = e.target.value.length;

      if (e.target.value.length < 6 && e.target.value.length !== 0) {
        setHsCodeError(true);
      } else {
        setHsCodeError(false);
      }

      if (inputLength >= 6 && inputLength <= 12) {
        values[i][e.target.name] = e.target.value + "0".repeat(12 - e.target.value.length);
        setCommoduties(values);
      }
    },
    [commoduties, setCommoduties]
  );

  const handleInputChange = useCallback(
    (e, i) => {
      const values = [...commoduties];

      if (e.target.name === "qty") {
        if (!/^\d*$/.test(e.target.value)) {
          return;
        }
        values[i][e.target.name] = Number(e.target.value);
      } else if (e.target.name === "price") {
        let priceValue = e.target.value.replace(/,/g, ".");

        // Virgül veya nokta sonrası en fazla iki hane olabilir
        const regex = /^\d*(\.|,)?\d{0,2}$/;

        // Noktadan veya virgülden önce sadece tek bir 0 olabilir
        const invalidZeroFormat = /^0{2,}\.\d*$/;

        // Giriş geçerli değilse veya geçersiz 0 formatına sahipse dönüş yap
        if (!regex.test(priceValue) || invalidZeroFormat.test(priceValue)) return;

        values[i][e.target.name] = priceValue;
      } else if (e.target.name === "country") {
        const code = CountriesTurkishSorted().find(
          (country) => `${country.country_name_tr}, ${country.country_name_en}` === e.target.value
        ).iso_code;
        values[i][e.target.name] = code;
      } else if (e.target.name === "title") {
        setProductDescriptionError(false);
        const value = e.target.value;
        values[i][e.target.name] = EngCharUpperCase(value);
        if (e.target.value.length > 8) {
          setProductDescriptionError(false);
        }
      } else if (e.target.name === "hs_code") {
        setTitleFocus(false);
        setHsCodeError(false);
        if (!/^\d*$/.test(e.target.value) || /^0$/.test(e.target.value)) {
          return;
        }

        if (e.target.value.length >= 6) {
          setHsCodeError(false);
        }
        values[i][e.target.name] = e.target.value;
      } else {
        values[i][e.target.name] = e.target.value;
      }

      values[i].total_price = Number(Number(values[i].qty * values[i].price).toFixed(2));

      setCommoduties(values);
    },
    [commoduties]
  );

  const handleInputBlur = useCallback(
    (e, i) => {
      const values = [...commoduties];

      // qty ve price değerlerinin her ikisi de dolu mu kontrol edelim
      const isQtyAndPriceFilled = values.every(
        (value) => value.qty !== "" && value.qty !== "0" && value.price !== ""
      );

      // Eğer her ikisi de dolu değilse işlem yapma
      if (!isQtyAndPriceFilled) {
        return;
      }

      // Toplam değeri kontrol et
      const totalValue = values.reduce((acc, curr) => {
        return acc + Number(curr.price) * Number(curr.qty);
      }, 0);

      if (
        totalValue > Number(productValue) &&
        supplierId !== 302 &&
        supplierId !== 337 &&
        supplierId !== 338 &&
        supplierId !== 339
      ) {
        setOverPayOpen(true);
        setOverPay(true); // overpay durumunda setOverPay'i de true yap
      } else {
        setOverPay(false);
      }
      setCommoduties(values);
    },
    [commoduties, productValue, setOverPay, setOverPayOpen, supplierId]
  );

  const handleInputInvoiceDateChange = (e) => {
    setEtgbInvoiceDate(e.target.value);
  }

  const handleDeleteCommoduties = useCallback(
    (key) => {
      if (commoduties.length > 1) {
        setCommoduties(commoduties.filter((commodutie) => commodutie.id !== key));
      }
    },
    [commoduties]
  );

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [sendFiles, setSendFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Math.random().toString(),
    }));
    setSendFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSetState = (id) => {
    setSendFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const [isChangeWarring, setIsChangeWarring] = useState(false);

  const handleCurrencyChange = useCallback(
    (e) => {
      if (e.target.value === productCurrency) {
        setCurrency(e.target.value);
        setCurrencyChange(false);
      } else {
        setCurrency(e.target.value);
        setCurrencyChange(true);
        setIsChangeWarring(true);
      }
    },
    [productCurrency]
  );

  const getIossList = useCallback(async () => {
    axios.get(`member-ioss-number?limit=999`).then((response) => {
      setIntegrations(response.data.data);
    });
  }, [setIntegrations]);

  const handleIOSSCreate = useCallback(() => {
    setProformaModal(true);
    setPlatformCountry(arrivalCountryCode);
    setNewIossNumberAnimation(true);
  }, [arrivalCountryCode, setProformaModal, setNewIossNumberAnimation, setPlatformCountry]);

  const productDescriptionBlur = (e) => {
    const value = e.target.value;
    if (value.length < 4) {
      setProductDescriptionError(true);
    } else setProductDescriptionError(false);
  };

  const totalPrice = commoduties.reduce((acc, com) => acc + Number(com.price) * Number(com.qty), 0);
  const isNotEvenPrice =
    Number(Number(totalPrice).toFixed(2)) !== Number(Number(productValue).toFixed(2));

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setHsCodeDialog(true);
  };

  const createShipment = async (e) => {
    if (e) e.preventDefault();


    if (isNotEvenPrice) {
      setNotEvenPriceDialog(true);
    } else {
      setLoading(true);

      let formData = new FormData();

      Array.from(selectedFiles).forEach((file, index) => {
        formData.append(`files[${index}]`, file.file);
      });

      //* price_details

      //TODO 15.12.2023 BARBAROS EKLEDİ
      formData.append("contract_ids", contractIds);

      formData.append("price_details[contract_code]", confirmationCode);
      formData.append("price_details[price_id]", priceId.toString());

      //* Other properties
      formData.append("sender_address_id", selectedSenderAddress?.id?.toString());
      formData.append("receiver_address_id", selectedReceiverAddress?.id?.toString());
      formData.append("parcel_type", shipmentDescription);
      formData.append("parcel_source", "1");
      formData.append(
        "doc_type",
        shipmentDocument === "N" ? "N" : shipmentDocument === "D" ? "D" : ""
      );

      //* incoterm
      let incotermValue;
      if (ddp === 0 && (supplierId === "337" || supplierId === "338")) {
        incotermValue = "DDP";
      } else {
        incotermValue = ddp === 1 ? "DDP" : "DDU";
      }
      formData.append("incoterm", incotermValue);

      //* Other properties continued
      formData.append("sender_name", selectedSenderAddress?.contact_name);
      formData.append("receiver_name", selectedReceiverAddress?.contact_name);
      formData.append("receiver_name", selectedReceiverAddress?.contact_name);
      formData.append("etgb_invoice_no", etgbInvoiceNo);
      formData.append("etgb_invoice_date", etgbInvoiceDate);
      formData.append("sender_country", "TR");
      formData.append("receiver_country", arrivalCountryCode);
      formData.append("gw", totalGw.toString());
      formData.append("cw", totalCw.toString());
      formData.append("vw", totalVw.toString());
      formData.append("insurance", insurance);
      formData.append("ddp", ddp);
      formData.append("lucid", lucid);

      //* dimensions and commoduties would typically need to be stringified if they are complex objects or arrays
      // Assuming each dimension is an object with properties like "width", "height", "depth"
      dimensionArray.forEach((dimension, index) => {
        Object.entries(dimension).forEach(([key, value]) => {
          formData.append(`dimensions[${index}][${key}]`, value.toString());
        });
      });

      // Similarly for commoduties if they are objects
      commoduties.forEach((commodity, index) => {
        Object.entries(commodity).forEach(([key, value]) => {
          formData.append(`commoduties[${index}][${key}]`, value.toString());
        });
      });

      //* Remaining properties
      formData.append("ioss", iossNumber);
      formData.append("qty", totalQty.toString());

      await axios
        .post("parcel/add", formData)
        .then((response) => {
          toast.success(response.data.message);
          setTimeout(
            setActiveStep((prevActiveStep) => prevActiveStep + 1),
            1000
          );
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  };

  const handleGoFirst = useCallback(() => {
    setIsChangeWarring(false);
    setCurrencyChange(false);
    setActiveStep(0);
  }, [setActiveStep]);

  const handleStayOnCourse = useCallback(() => {
    setIsChangeWarring(false);
    setCurrencyChange(false);
    setCurrency(productCurrency);
  }, [setCurrency, productCurrency]);

  useEffect(() => {
    setTotalQty(
      dimensionArray?.map((e) => Number(e.qty)).reduce((prev, curr, index, array) => prev + curr, 0)
    );
  }, [dimensionArray, setTotalQty]);

  useEffect(() => {
    getIossList();
  }, [getIossList]);

  useEffect(() => {
    if (titleFocus && hsCodeRef.current) {
      hsCodeRef.current.focus();
    }
  }, [titleFocus]);

  return (
    <ThemeProvider theme={theme}>
      <CreateProformaIOSSModal />
      <ProformaOverPay />
      <ProformaHSCodeErrorDialog
        open={hsCodeDialog}
        setOpen={setHsCodeDialog}
        setFocus={setTitleFocus}
        handleSubmit={createShipment}
      />
      <ToastContainer />
      <ProformaWarning
        notEvenPriceDialog={notEvenPriceDialog}
        setNotEvenPriceDialog={setNotEvenPriceDialog}
      />
      <ProformaIossError open={openWarningModal}
        setOpen={setOpenWarningModal} />
      <form onSubmit={isFilledHsCode ? handleSubmit : createShipment} className="shipmentProforma">
        <h6 className="proforma-detail-headline">Proforma Detayları</h6>
        {iossCountries?.find((e) => e.iso_code === arrivalCountryCode) && (
          <div className="proforma-info">
            <InfoOutlinedIcon sx={{ color: "var(--btnBgBlue)", width: "15px", height: "15px" }} />
            <div className="info-text">
              <div className="text-header">
                Seçilen tedarikçi ile IOSS numarası ve HSCODE(GTIP NO) girilmesi zorunludur.
              </div>
              <div className="text-content">
                IOSS numarası sadece {Currency("EUR")}
                {LocalPrice(150)} değere kadar geçerlidir. {Currency("EUR")}
                {LocalPrice(150)} üzeri değerlerde veya yanlış girilecek olan IOSS numaraları için
                navlun ücretinize ek olarak, ürünün satış bedeli üzerinden %21 KDV +{" "}
                {Currency("EUR")}
                {LocalPrice(25)} yurt dışı gümrükleme ücreti yansıtılacaktır.
              </div>
            </div>
          </div>
        )}
        <div className="proforma-number">
          <FormControl required size="small">
            <InputLabel id="select-product-description">Gönderi Açıklaması</InputLabel>
            <Select
              labelId="select-product-description"
              id="select-simple-product-description"
              value={shipmentDescription}
              onChange={(e) => setShipmentDescription(e.target.value)}
              label="Gönderi Açıklaması"
              required
            >
              <MenuItem value={""}>
                <em>Temizle</em>
              </MenuItem>
              <MenuItem disabled={supplierId === 336} value={1}>Normal Gönderi veya Numune</MenuItem>
              <MenuItem disabled={supplierId === 336 || supplierId === 701 || supplierId === 801 || supplierId === 901} value={2}>Hediye</MenuItem>
              <MenuItem disabled={supplierId === 347 || supplierId === 701 || supplierId === 801 || supplierId === 901 || supplierId === 306} value={3}>Mikro İhracat (ETGB'li) E-ihracat</MenuItem>
            </Select>
          </FormControl>
          <FormControl required size="small">
            <InputLabel id="select-currency">Döviz</InputLabel>
            <Select
              labelId="select-currency"
              id="select-simple-currency"
              value={currency}
              onChange={handleCurrencyChange}
              label="Döviz"
              required
            >
              <MenuItem value={""}>
                <em>Temizle</em>
              </MenuItem>
              <MenuItem value={"EUR"}>€</MenuItem>
              <MenuItem value={"USD"}>$</MenuItem>
              <MenuItem value={"GBP"}>£</MenuItem>
            </Select>
            {currencyChange && (
              <FormHelperText style={{ color: " var(--error)", display: "flex" }}>
                <InfoOutlinedIcon
                  sx={{
                    color: "var(--error)",
                    width: "15px",
                    height: "15px",
                    marginRight: "2px",
                  }}
                />{" "}
                Döviz cinsi değiştirildi.
              </FormHelperText>
            )}
          </FormControl>
          {iossCountries?.find((e) => e.iso_code === arrivalCountryCode) && (
            <FormControl size="small">
              <InputLabel id="select-ioss">IOSS/VAT</InputLabel>
              <Select
                labelId="select-ioss"
                id="select-simple-ioss"
                value={iossNumber}
                onChange={(e) => setIossNumber(e.target.value)}
                label="IOSS/VAT"
                error={ddp === 0 && (
                  !isAbove150EURCheck && (supplierId === 302 || supplierId === 338) && isEuropeanCountry && !iossNumber
                )}
              >
                <MenuItem value={""}>
                  <em>Temizle</em>
                </MenuItem>
                <MenuItem
                  sx={{ color: "var(--btnBgBlue)", textDecoration: "underline" }}
                  value={""}
                  onClick={handleIOSSCreate}
                >
                  <em>IOSS Kaydı Oluştur</em>
                </MenuItem>
                {integrations?.map((integration) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      gap: "var(--gap)",
                      alignItems: "center",
                    }}
                    key={integration.id}
                    value={integration.ioss_number}
                  >
                    <img
                      src={`/images/integrations/${integration.market_place}.svg`}
                      style={{
                        width: "50px",
                        height: "20px",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      alt={`${integration.market_place} logo`}
                    />{" "}
                    {integration.ioss_number}
                  </MenuItem>
                ))}
              </Select>
              {ddp === 0 && (
                !isAbove150EURCheck && (supplierId === 302 || supplierId === 338) && isEuropeanCountry && !iossNumber && (
                  <FormHelperText style={{ color: 'red' }}>
                    IOSS numarası zorunlu alandır. IOSS numaranız yoksa fiyat kartına giderek "Yurtdışı Gümrükleme" seçeneğinizi seçiniz.
                  </FormHelperText>
                )
              )}
            </FormControl>
          )}
        </div>
        <div className="proforma-details">
          <div className="details-columns">
            {commoduties.map((com, key) => (
              <div key={key}>
                <div className="details-column">
                  <div className="column">
                    <Autocomplete
                      id="country-typing-select"
                      fullWidth
                      size="small"
                      sx={{ maxWidth: 380 }}
                      autoComplete="off"
                      name="country"
                      defaultValue={defaultCountry}
                      options={CountriesTurkishSorted()}
                      autoHighlight
                      getOptionLabel={(option) =>
                        `${option.country_name_tr}, ${option.country_name_en}`
                      }
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.country_name_tr}, {option.country_name_en}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Menşei"
                          required
                          autoComplete="off"
                          name="country"
                          onBlur={(e) => handleInputChange(e, key)}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <div>
                      <TextField
                        required
                        value={com.title}
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, key)}
                        onBlur={productDescriptionBlur}
                        error={productDescriptionError}
                        helperText={
                          productDescriptionError ? "Ürün açıklaması 4 haneden az olamaz" : ""
                        }
                        name="title"
                        size="small"
                        label="Ürün Açıklaması"
                        inputProps={{
                          pattern: "^[a-zA-Z -/]+$",
                          minLength: 4,
                        }}
                      />
                      {!productDescriptionError && (
                        <FormHelperText style={{ color: " var(--info)", display: "flex" }}>
                          <InfoOutlinedIcon
                            sx={{
                              color: "var(--btnBgBlue)",
                              width: "15px",
                              height: "15px",
                              marginRight: "2px",
                            }}
                          />{" "}
                          Ürün açıklaması ingilizce olmalıdır
                        </FormHelperText>
                      )}
                    </div>
                    <TextField
                      value={com.hs_code}
                      onChange={(e) => handleInputChange(e, key)}
                      name="hs_code"
                      size="small"
                      focused={titleFocus}
                      inputRef={hsCodeRef}
                      label="HS Kodu"
                      autoComplete="off"
                      inputProps={{
                        minLength: 6,
                        maxLength: 12,
                      }}
                      onBlur={(e) => handleHsCodeBlur(e, key)}
                      error={hsCodeError}
                      helperText={
                        <>
                          {hsCodeError && "Hs Kodu 6 haneden küçük girilemez."}
                          {!hsCodeError && (
                            <a
                              rel="noreferrer"
                              href="https://uygulama.gtb.gov.tr/Tara/"
                              target="_blank"
                              tabIndex={-1}
                              style={{
                                fontSize: "inherit",
                                color: "var(--btnBgBlue)",
                              }}
                            >
                              GTIP - HSCODE sorgula
                            </a>
                          )}
                        </>
                      }
                    />
                    <TextField
                      value={com.sku}
                      onChange={(e) => handleInputChange(e, key)}
                      name="sku"
                      size="small"
                      label="SKU"
                      autoComplete="off"
                    />
                    <TextField
                      required
                      value={com.qty}
                      onChange={(e) => handleInputChange(e, key)}
                      name="qty"
                      size="small"
                      label="Miktar"
                      autoComplete="off"
                      inputProps={{
                        min: 1, // En küçük değer sıfır olmalıdır
                      }}
                      error={com.qty < 1 && com.qty.length !== 0}
                      helperText={
                        com.qty < 1 && com.qty.length !== 0 && "Miktar 1'dan küçük olamaz."
                      }
                    />
                    <TextField
                      value={com.price}
                      onChange={(e) => handleInputChange(e, key)}
                      onBlur={(e) => handleInputBlur(e, key)}
                      name="price"
                      required
                      size="small"
                      label="Birim Fiyatı"
                      autoComplete="off"
                    />
                  </div>
                  <DeleteIcon
                    className={key === 0 ? "display displayHide" : "display"}
                    onClick={() => handleDeleteCommoduties(key)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "var(--gap)",
            }}
          >
            <div>
              <div
                style={{
                  backgroundColor: "var(--background)",
                  boxShadow: "var(--shadow)",
                  padding: "10px",
                  borderRadius: "var(--inputBorderRadius)",
                  color: "var(--priceText)",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Toplam Fiyat: {Currency(currency)}
                {LocalPrice(totalPrice)}
              </div>
            </div>
          </div>
          <Tooltip
            title={
              addInputDisabled ? "Gerekli alanları doldurmadan proforma satırı ekleyemezsiniz!" : ""
            }
          >
            <AddCircleIcon
              onClick={handleAddInput}
              className={addInputDisabled ? "proforma-add disabled" : "proforma-add active"}
            />
          </Tooltip>
        </div>
        {supplierId === 341 && shipmentDescription === 3 && (
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
              borderRadius: "var(--inputBorderRadius)",
            }}
          >
            <div style={{
              display: "flex",
              gap: "10px"
            }}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px"
              }}>
                <label>
                  Fatura Numarası
                </label>
                <TextField
                  name="etgb_invoice_no"
                  onChange={(e) => setEtgbInvoiceNo(e.target.value)}
                  size="small"
                  autoComplete="off"
                  value={etgbInvoiceNo}
                />
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px"
              }}>
                <label>
                  Fatura Tarihi
                </label>
                <TextField
                  name="etgb_invoice_date"
                  onChange={(e) => setEtgbInvoiceDate(e.target.value)}
                  type="date"
                  size="small"
                  autoComplete="off"
                  value={etgbInvoiceDate}
                />
              </div>
            </div>
          </div>
        )}
        {Number(shipmentDescription) === 3 && (
          <div
            style={{
              boxShadow: "var(--shadow)",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
              borderRadius: "var(--inputBorderRadius)",
            }}
          >
            {/* {
              supplierId === 341 && shipmentDescription === 3 && (
                <div style={
                  {
                    display: "flex",
                    gap: "10px"
                  }
                }>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}>
                    <label>
                      Fatura Numarası
                    </label>
                    <TextField
                      name="etgb_invoice_no"
                      // onChange={(e) => handleInputInvoiceNoChange(e)}
                      type="text"
                      size="small"
                      autoComplete="off"
                    />
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}>
                    <label>
                      Fatura Tarihi
                    </label>
                    <TextField
                      name="etgb_invoice_date"
                      // onChange={(e) => handleInputInvoiceChange(e)}
                      type="date"
                      size="small"
                      autoComplete="off"
                    />
                  </div>
                </div>
              )
            } */}
            <div
              style={{
                backgroundColor: "var(--pastelBlue)",
                padding: "10px 20px",
                borderRadius: "var(--wrapperBorderRadius)",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "var(--btnBgBlue)",
              }}
            >
              <InfoOutlinedIcon sx={{ color: "inherit", fontWeight: 600 }} />
              <div style={{ color: "inherit" }}>
                <span style={{ fontWeight: 600 }}>
                  Talep edilen evraklar;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span style={{ fontWeight: 300 }}>Türkçe E-arşiv fatura (Zorunlu)</span>
              </div>
            </div>
            <Box className="dropzone-wrapper">
              <Box className="dropzone">
                <Button className="dropzone-area" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dropzone-area-text">
                    <img src={uploadIcon} alt="Upload Icon" />
                    <span className="dropzone-area-text-headline">
                      Dosyayı sürükleyip bırakın veya{" "}
                      <span style={{ color: "var(--btnBgBlue)" }}>Gözat</span>
                    </span>
                    <span className="dropzone-area-text-sub">
                      Desteklenen Formatlar: JPEG, PNG, PDF
                    </span>
                  </div>
                </Button>
              </Box>
              <DowloadPdfView sendFiles={sendFiles} handleSetState={handleSetState} />
            </Box>
          </div>
        )}
        <div
          style={{
            color: " var(--info)",
            display: "flex",
            gap: "3px",
            marginTop: "5px",
            fontSize: "14px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <InfoOutlinedIcon sx={{ color: "var(--btnBgBlue)" }} /> İlk aşamada belirlediğiniz mal
          bedeli (Miktar * Birim Fiyatı):{" "}
          <span style={{ fontSize: "inherit", color: "var(--shipmentRed)" }}>
            {Currency(productCurrency)}
            {LocalPrice(productValue)}
          </span>
        </div>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifySelf: "flex-end",
              justifyContent: "center",
              alignItems: "center",
              width: "69px",
              height: "27px",
            }}
          >
            <CircularProgress
              sx={{
                width: "27px!important",
                height: "27px!important",
              }}
            />
          </Box>
        ) : (
          <Button
            sx={{
              ":disabled": { opacity: 0.3 },
            }}
            type="submit"
            className="iyzi-button-blue"
            disabled={ddp === 0 && (
              !isAbove150EURCheck && (supplierId === 302 || supplierId === 338) && isEuropeanCountry && !iossNumber
            )}
          >
            Gönderi Oluşturmayı Tamamla
          </Button>
        )}
      </form>
      <Dialog
        open={isChangeWarring}
        onClose={handleStayOnCourse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Uyarı"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontWeight: 500, fontSize: "16px" }}
            id="alert-dialog-description"
          >
            Döviz Cinsini değiştirdiğinizde gönderi oluşturmaya devam edemezsiniz! Eğer
            değiştirmeniz gerekiyorsa başa dönün ve döviz cinsini tekrar belirleyin veya belirlemiş
            olduğunuz döviz cinsinden devam ediniz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handleGoFirst}>
            Başa Dön
          </Button>
          <Button className="iyzi-button" onClick={handleStayOnCourse}>
            Devam Et
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ShipmentProforma;
